<template>
	<el-form-item
		:label="item.label"
		:prop="item.prop"
		:style="item.col ? 'width:' + item.col : item.width ? 'width:' + item.width : ''"
	>
		<slot :name="item.prop" :row="{ prop: item, value: form[item.prop] }">
			<div class="image" v-if="item.type === 'avatar'">
				<InfoAvatar :url="form[item.prop]"></InfoAvatar>
			</div>
			<div v-else-if="item.type === 'image'" style="width: 100%">
				<InfoImage :batch-no="form[item.prop]" :group="item['group']"></InfoImage>
			</div>
			<div v-else-if="item.type === 'file'" style="width: 100%">
				<InfoFile :batch-no="form[item.prop]" :group="item['group']"></InfoFile>
			</div>
			<div v-else-if="item.type === 'emp'">
				<InfoEmp
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:emps="form[item.prop]"
				></InfoEmp>
			</div>
			<div v-else-if="item.type === 'dept'">
				<InfoDept
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:depts="form[item.prop]"
				></InfoDept>
			</div>
			<div v-else-if="item.type === 'deptemp'">
				<InfoDeptEmp
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:list="form[item.prop]"
				></InfoDeptEmp>
			</div>
			<div v-else-if="item.type === 'document'">
				<InfoDocument
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:documents="form[item.prop]"
				></InfoDocument>
			</div>
			<div v-else-if="item.type === 'registration'">
				<InfoRegistration
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:list="form[item.prop]"
				></InfoRegistration>
			</div>
			<div v-else-if="item.type === 'activity'">
				<InfoActivity
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:list="form[item.prop]"
				></InfoActivity>
			</div>
			<div v-else-if="item.type === 'class'">
				<InfoClass
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:list="form[item.prop]"
				></InfoClass>
			</div>
			<div v-else-if="item.type === 'school'">
				<InfoSchool
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:schools="form[item.prop]"
				></InfoSchool>
			</div>
			<div v-else-if="item.type === 'assets'">
				<InfoAssets
					v-if="form[item.prop] && Array.isArray(form[item.prop]) && form[item.prop].length > 0"
					:assets="form[item.prop]"
				></InfoAssets>
			</div>
			<div v-else-if="item.type === 'select'">
				{{ handlePreviewSelect(item, form[item.prop]) }}
			</div>
			<div v-else-if="item.type === 'switch'">
				<el-tag v-if="form[item.prop] === 1" type="success">是</el-tag>
				<el-tag v-if="form[item.prop] === 0" type="danger">否</el-tag>
			</div>
			<div v-else-if="item.type === 'radio'">
				{{ item.options.find((o) => o.value == form[item.prop])?.label }}
			</div>
			<div v-else-if="item.type === 'color'">
				<!-- {{ item.options.find((o) => o.value == form[item.prop])?.label }} -->
				{{ form[item.prop] }}

				<el-color-picker
					v-model="form[item.prop]"
					:size="item.size ? item.size : ''"
					:disabled="item.disabled"
				/>
			</div>
			<div v-else-if="item.type === 'checkbox-group'">
				<template v-if="item.color">
					<el-color-picker
						style="margin-right: 10px"
						v-for="(item, index) in item.options"
						:key="index"
						v-model="item.color"
						size="large"
					/>
				</template>
				<template v-else>
					<span style="margin-right: 10px" v-for="(item, index) in form[item.prop]" :key="index">{{
						item
					}}</span>
				</template>
			</div>
            <div v-else-if="item.type==='editor'" v-html="form[item.prop]"></div>
			<div v-else>
				{{ form[item.prop] }}
			</div>
		</slot>
	</el-form-item>
</template>

<script setup>
import { ref } from 'vue'

import UploadAvatar from '../upload/Avatar'
import UploadImage from '../upload/Image'
import UploadFile from '../upload/File'
import ChooseEmp from '../choose/Emp'
import ChoosePost from '../choose/Post'
import ChooseDept from '../choose/Dept'
import ChooseDeptEmp from '../choose/DeptEmp'
import ChooseSchool from '../choose/School'
import ChooseRole from '../choose/Role'
import ChooseGovjob from '../choose/GovJob'
import ChooseRegion from '../choose/Region'
import ChooseDocument from '../choose/Document'
import ChoosePeople from '../choose/People'
import ChooseActivity from '../choose/Activity'
import ChooseHotelRoom from '../choose/HotelRoom.vue'
import ChooseCurriculum from '../choose/Curriculum.vue'
import ChooseClass from '../choose/Class.vue'
import ChooseAssets from '../choose/Assets.vue'
import ChooseRegistration from '../choose/Registration.vue'
import InfoFile from '../info/File.vue'
import InfoImage from '../info/Image.vue'
import InfoAvatar from '../info/Avatar.vue'
import InfoEmp from '../info/Emp.vue'
import InfoDeptEmp from '../info/DeptEmps.vue'
import InfoActivity from '../info/Activity.vue'
import InfoClass from '../info/Class.vue'
import InfoDept from '../info/Dept.vue'
import InfoAssets from '../info/Assets.vue'
import InfoSchool from '../info/School.vue'
import InfoDocument from '../info/Document.vue'
import InfoRegistration from '../info/Registration.vue'
import GoodsType from '../choose/GoodsType.vue'
import StoreList from '../choose/StoreList.vue'
import VaultSrc from '../choose/VaultSrc.vue'
import Supplier from '../choose/Supplier.vue'
import Shipper from '../choose/Shipper.vue'
import Comp from '../choose/Comp.vue'
import Aisle from '../choose/Aisle.vue'
import ClassType from '../choose/ClassType.vue'
import FormItem from './FormItem.vue'
import WangEditor from './WangEditor.vue'

const props = defineProps({
	form: {
		type: Object,
		default: () => ({})
	},
	item: {
		type: Object,
		default: () => ({})
	}
})

function handlePreviewSelect(prop, value) {
	if (prop['options'] && Array.isArray(prop['options'])) {
		if (prop['multiple']) {
			if (value && Array.isArray(value)) {
				return value
					.map((m) => {
						return prop['options'].find((o) => o.value === m)?.label ?? ''
					})
					.join('、')
			} else {
				return ''
			}
		}
		return prop['options'].find((o) => o.value === value)?.label
	}
	return ''
}
</script>

<style lang="scss" scoped></style>

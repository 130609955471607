import SparkMD5 from 'spark-md5'

export function fileType(file) {
	return new Promise((resolve, reject) => {
		var fr = new FileReader()
		fr.onloadend = function (e) {
            const fileType = file.type;
            console.log('文件类型:', fileType);
            resolve(file.type);
		}
		fr.onerror = function () {
			reject('获取文件错误')
		}

		// 以数据URL的形式读取文件
		fr.readAsDataURL(file)
	})
}

/**
 * 获取文件的md5值
 * @param {*} file
 * @returns
 */
export function fileMd5(file) {
	return new Promise((resolve, reject) => {
		var spark = new SparkMD5.ArrayBuffer()
		var fr = new FileReader()
		fr.onloadend = function (e) {
			spark.append(e.target.result)
			var md5 = spark.end()
			resolve(md5)
		}
		fr.onerror = function () {
			reject('获取文件md5值错误')
		}
		fr.readAsArrayBuffer(file)
	})
}

/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-07-11 17:39:52
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-14 21:50:10
 */
import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import Layout from "@/layout";
import RouteLayout from "@/layout/layout2.vue";
import menus from "@/config/menus.js";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login"),
  }, 
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile"),
  },
  {
    path: "/paytool",
    name: "paytool",
    component: () => import("@/views/h5/paytool"),
  },
  {
    path: "/bao/written",
    name: "bao_written",
    component: () => import("@/views/h5/bao/written"),
  },
  {
    path: "/bao/interview",
    name: "bao_interview",
    component: () => import("@/views/h5/bao/interview"),
  }
];

menus.forEach((item) => {
  item.component = Layout;
  routes.push(item);
});

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  next();
});

/**
 * 后置守卫
 */
router.afterEach(() => {
  NProgress.done();
});

export default router;

/*
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-06 09:24:00
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-19 12:03:57
 * @FilePath: \dc.amin\src\config\menu_finance.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
	path: '/',
	icon: '',
	component: '',
	meta: {
		title: '财务管理'
	},
	children: [
		{
			path: '/',
			icon: '',
			meta: {
				title: '缴费审核'
			},
			children: [
				{
					path: '/finance/money/audits',
					icon: '',
					component: () => import('@/views/finance/money/audits'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '缴费待核'
					}
				},
				{
					path: '/finance/money/auditsTrail',
					icon: '',
					component: () => import('@/views/finance/money/auditsTrail'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '缴费审核记录'
					}
				},
			]
		},
		{
			path: '/',
			icon: '',
			component: '',
			meta: {
				title: '退费管理'
			},
			children: [
				{
					path: '/finance/refundMgr/processedRefunds',
					icon: '',
					component: () => import('@/views/finance/refundMgr/processedRefunds'),
					power: 'admin/v1/emp',
					meta: {
						title: '退费记录'
					}
				},
				{
					path: '/finance/refundMgr/newRefunds',
					icon: '',
					component: () => import('@/views/finance/refundMgr/newRefunds'),
					power: 'admin/v1/emp',
					meta: {
						title: '新退费'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			component: '',
			meta: {
				title: '成本预估'
			},
			children: [
				{
					path: '/finance/estCost/actCost',
					icon: '',
					component: () => import('@/views/finance/estCost/actCost'),
					power: 'admin/v1/emp',
					meta: {
						title: '活动成本'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '工资管理'
			},
			children: [
				{
					path: '/finance/income/deduction',
					icon: '',
					component: () => import('@/views/finance/income/deduction'),
					power: 'admin/v1/emp',
					meta: {
						title: '补助扣款'
					}
				},
				{
					path: '/finance/income/wageAdmin',
					icon: '',
					component: () => import('@/views/finance/income/wageAdmin'),
					power: 'admin/v1/emp',
					meta: {
						title: '工资管理'
					}
				},
				{
					path: '/finance/income/commission',
					icon: '',
					component: () => import('@/views/finance/income/commission'),
					power: 'admin/v1/emp',
					meta: {
						title: '销售提成'
					}
				}
			]
		},
	]
}

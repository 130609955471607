export default {
	path: '/',
	icon: '',
	redirect: '/wmg/skus',
	meta: {
		title: '仓库管理'
	},

	children: [
		{
			path: '/wmg/skus',
			icon: '',
			component: () => import('@/views/wmg/SkusList'),
			power: 'admin/v1/day',
			meta: {
				title: '库存管理'
			}
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '基础设置'
			},
			children: [
				{
					path: '/wmg/settings/goodsman',
					icon: '',
					component: () => import('@/views/wmg/settings/goodsMan'),
					power: 'admin/v1/day',
					meta: {
						title: '商品管理'
					}
				},
				{
					path: '/wmg/settings/storesettings',
					icon: '',
					component: () => import('@/views/wmg/settings/storeSettings'),
					power: 'admin/v1/day',
					meta: {
						title: '仓库设置'
					}
				},
				{
					path: '/wmg/settings/prodcat',
					icon: '',
					component: () => import('@/views/wmg/settings/prodCat'),
					power: 'admin/v1/day',
					meta: {
						title: '商品类别'
					}
				},
				{
					path: '/wmg/settings/vendor',
					icon: '',
					component: () => import('@/views/wmg/settings/vendor'),
					power: 'admin/v1/day',
					meta: {
						title: '供应商'
					}
				},
				{
					path: '/wmg/settings/areaset',
					icon: '',
					component: () => import('@/views/wmg/settings/areaSet'),
					power: 'admin/v1/day',
					meta: {
						title: '库区设置'
					}
				},
				{
					path: '/wmg/settings/sloconfig',
					icon: '',
					component: () => import('@/views/wmg/settings/slotConfig'),
					power: 'admin/v1/day',
					meta: {
						title: '库位设置'
					}
				},
				{
					path: '/wmg/settings/cargomgr',
					icon: '',
					component: () => import('@/views/wmg/settings/cargoMgr'),
					power: 'admin/v1/day',
					meta: {
						title: '货主管理'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '收货管理'
			},
			children: [
				// {
				//     path: '/wmg/receivegoods/details',
				//     icon: '',
				//     component: () => import('@/views/wmg/receiveGoods/details'),
				//     power: 'admin/v1/day',
				//     meta: {
				//         title: '收货明细'
				//     }
				// },
				{
					path: '/wmg/receivegoods/shelf',
					icon: '',
					component: () => import('@/views/wmg/receiveGoods/shelf'),
					power: 'admin/v1/day',
					meta: {
						title: '待上架'
					}
				},
				{
					path: '/wmg/receivegoods/sorting',
					icon: '',
					component: () => import('@/views/wmg/receiveGoods/sorting'),
					power: 'admin/v1/day',
					meta: {
						title: '待分捡'
					}
				},
				{
					path: '/wmg/receivegoods/unloading',
					icon: '',
					component: () => import('@/views/wmg/receiveGoods/unloading'),
					power: 'admin/v1/day',
					meta: {
						title: '待卸货'
					}
				},
				{
					path: '/wmg/receivegoods/arrival',
					icon: '',
					component: () => import('@/views/wmg/receiveGoods/arrival'),
					power: 'admin/v1/day',
					meta: {
						title: '待到货'
					}
				},
				{
					path: '/wmg/receivegoods/alert',
					icon: '',
					component: () => import('@/views/wmg/receiveGoods/alert'),
					power: 'admin/v1/day',
					meta: {
						title: '到货通知'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '发货管理'
			},
			children: [
				{
					path: '/wmg/ship/order',
					icon: '',
					component: () => import('@/views/wmg/ship/order'),
					power: 'admin/v1/day',
					meta: {
						title: '发货单'
					}
				},
				{
					path: '/wmg/ship/pick',
					icon: '',
					component: () => import('@/views/wmg/ship/pick'),
					power: 'admin/v1/day',
					meta: {
						title: '待拣货'
					}
				},
				{
					path: '/wmg/ship/pack',
					icon: '',
					component: () => import('@/views/wmg/ship/pack'),
					power: 'admin/v1/day',
					meta: {
						title: '待打包'
					}
				},
				{
					path: '/wmg/ship/outbound',
					icon: '',
					component: () => import('@/views/wmg/ship/outbound'),
					power: 'admin/v1/day',
					meta: {
						title: '待出库'
					}
				}
			]
		}
	]
}

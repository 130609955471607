<!--
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-25 10:41:18
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-27 10:51:16
 * @FilePath: \dc.amin\src\components\dc\choose\Supplier.vue
 * @Description: 供应商组件
-->
<template>
	<el-select
		v-model="model"
		filterable
		:remote-method="remoteMethod"
		v-bind="$attrs"
		placeholder="请选择"
		value-key="supplier_id"
		@focus="handelrList"
	>
		<el-option
			v-for="item in options"
			:key="item.id"
			:label="item.name"
			:value="props.attrs.isName ? { supplier_id: item.id, supplier_name: item.name } : item.id"
		/>
	</el-select>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { apiGetSupplierList } from '@/api/public.js'
const model = defineModel()
const options = ref([])
const loading = ref(false)

const props = defineProps({
	attrs: {
		type: Object,
		default: () => ({
			isName: false
		})
	}
})

const handlerList = async (query = '') => {
	loading.value = true
	const { data } = await apiGetSupplierList({
		page: 1,
		limit: 50,
		keywords: query
	})
	options.value = data.data
	loading.value = false
}

const remoteMethod = (query) => {
	handlerList(query)
}

onMounted(() => {
	handlerList()
})
</script>

<style lang="scss" scoped></style>

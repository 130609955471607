/**
 * @Author: Sky
 * @Date: 2024-09-23 14:16:52
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-25 14:34:46
 * @Description: desc
 **/

import http from '@/http/axios_init.js'

/**
 * 来源列表
 * @param {*} data
 * @returns
 */
export function apiFaSourceList(data) {
	return http.post('admin/v1/fa/source', data)
}

/**
 * 来源添加
 * @param {*} data
 * @returns
 */
export function apiFaSourceAdd(data) {
	return http.post('admin/v1/fa/source/add', data)
}

/**
 * 来源编辑
 * @param {*} data
 * @returns
 */
export function apiFaSourceEdit(data) {
	return http.post('admin/v1/fa/source/edit', data)
}

/**
 * 来源删除
 * @param {*} data
 * @returns
 */
export function apiFaSourceDel(data) {
	return http.post('admin/v1/fa/source/del', data)
}

/**
 * 来源详情
 * @param {*} data
 * @returns
 */
export function apiFaSourceInfo(data) {
	return http.post('admin/v1/fa/source/get_info', data)
}

/**
 * 获取配置项
 * @param {*} data
 * @returns
 */
export function apiFaCodeRuleItems(data) {
	return http.post('admin/v1/fa/code_rule/items', data)
}

/**
 * 获取间隔符下拉项
 * @param {*} data
 * @returns
 */
export function apiFaCodeRuleDropdownMenu(data) {
	return http.post('admin/v1/fa/code_rule/dropdown_menu', data)
}

/**
 * 编码列表
 * @param {*} data
 * @returns
 */
export function apiFaCodeRuleList(data) {
	return http.post('admin/v1/fa/code_rule', data)
}

/**
 * 编码保存
 * @param {*} data
 * @returns
 */
export function apiFaCodeRuleAdd(data) {
	return http.post('admin/v1/fa/code_rule/add', data)
}

/**
 * 资产分类列表
 * @param {*} data
 * @returns
 */
export function apiFaTypeList(data) {
	return http.post('admin/v1/fa/type', data)
}

/**
 * 资产分类新增
 * @param {*} data
 * @returns
 */
export function apiFaTypeAdd(data) {
	return http.post('admin/v1/fa/type/add', data)
}

/**
 * 资产分类编辑
 * @param {*} data
 * @returns
 */
export function apiFaTypeEdit(data) {
	return http.post('admin/v1/fa/type/edit', data)
}

/**
 * 资产分类规格列表
 * @param {*} data
 * @returns
 */
export function apiFaTypeSauList(data) {
	return http.post('admin/v1/fa/type/sau', data)
}

/**
 * 资产分类规格新增
 * @param {*} data
 * @returns
 */
export function apiFaTypeSauAdd(data) {
	return http.post('admin/v1/fa/type/sau/add', data)
}

/**
 * 资产分类规格编辑
 * @param {*} data
 * @returns
 */
export function apiFaTypeSauEdit(data) {
	return http.post('admin/v1/fa/type/sau/edit', data)
}

/**
 * 资产分类规格删除
 * @param {*} data
 * @returns
 */
export function apiFaTypeSauDel(data) {
	return http.post('admin/v1/fa/type/sau/del', data)
}

/**
 * 资产分类规格详情
 * @param {*} data
 * @returns
 */
export function apiFaTypeSauInfo(data) {
	return http.post('admin/v1/fa/type/sau/get_info', data)
}

/**
 * 资产分类详情
 * @param {*} data
 * @returns
 */
export function apiFaTypeInfo(data) {
	return http.post('admin/v1/fa/type/get_info', data)
}

/**
 * 资产分类删除
 * @param {*} data
 * @returns
 */
export function apiFaTypeDel(data) {
	return http.post('admin/v1/fa/type/del', data)
}

/**
 * 资产管理列表
 * @param {*} data
 * @returns
 */
export function apiFaAssetList(data) {
	return http.post('admin/v1/fa/asset', data)
}

/**
 * 资产管理新增
 * @param {*} data
 * @returns
 */
export function apiFaAssetAdd(data) {
	return http.post('admin/v1/fa/asset/add', data)
}

/**
 * 资产管理编辑
 * @param {*} data
 * @returns
 */
export function apiFaAssetEdit(data) {
	return http.post('admin/v1/fa/asset/edit', data)
}

/**
 * 资产管理详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetInfo(data) {
	return http.post('admin/v1/fa/asset/get_info', data)
}

/**
 * 资产管理删除
 * @param {*} data
 * @returns
 */
export function apiFaAssetDel(data) {
	return http.post('admin/v1/fa/asset/del', data)
}

/**
 * 领用列表
 * @param {*} data
 * @returns
 */
export function apiFaAssetRequisiton(data) {
	return http.post('admin/v1/fa/asset/requisiton', data)
}

/**
 * 领用新增
 * @param {*} data
 * @returns
 */
export function apiFaAssetRequisitonAdd(data) {
	return http.post('admin/v1/fa/asset/requisiton/add', data)
}

/**
 * 领用详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetRequisitonInfo(data) {
	return http.post('admin/v1/fa/asset/requisiton/get_info', data)
}

/**
 * 借用列表
 * @param {*} data
 * @returns
 */
export function apiFaAssetLend(data) {
	return http.post('admin/v1/fa/asset/lend', data)
}

/**
 * 借用新增
 * @param {*} data
 * @returns
 */
export function apiFaAssetLendAdd(data) {
	return http.post('admin/v1/fa/asset/lend/add', data)
}

/**
 * 借用详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetLendInfo(data) {
	return http.post('admin/v1/fa/asset/lend/get_info', data)
}

/**
 * 借用归还
 * @param {*} data
 * @returns
 */
export function apiFaAssetLendDoReturn(data) {
	return http.post('admin/v1/fa/asset/lend/do_return', data)
}

/**
 * 维修列表
 * @param {*} data
 * @returns
 */
export function apiFaAssetRepair(data) {
	return http.post('admin/v1/fa/asset/repair', data)
}

/**
 * 维修新增
 * @param {*} data
 * @returns
 */
export function apiFaAssetRepairAdd(data) {
	return http.post('admin/v1/fa/asset/repair/add', data)
}

/**
 * 维修详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetRepairInfo(data) {
	return http.post('admin/v1/fa/asset/repair/get_info', data)
}

/**
 * 维修编辑
 * @param {*} data
 * @returns
 */
export function apiFaAssetRepairEdit(data) {
	return http.post('admin/v1/fa/asset/repair/edit', data)
}

/**
 * 清理报废
 * @param {*} data
 * @returns
 */
export function apiFaAssetCleared(data) {
	return http.post('admin/v1/fa/asset/cleared', data)
}

/**
 * 清理报废添加
 * @param {*} data
 * @returns
 */
export function apiFaAssetClearedAdd(data) {
	return http.post('admin/v1/fa/asset/cleared/add', data)
}

/**
 * 清理报废详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetClearedInfo(data) {
	return http.post('admin/v1/fa/asset/cleared/get_info', data)
}

/**
 * 清理报废恢复
 * @param {*} data
 * @returns
 */
export function apiFaAssetClearedDoRecover(data) {
	return http.post('admin/v1/fa/asset/cleared/do_revert', data)
}

/**
 * 退库列表
 * @param {*} data
 * @returns
 */
export function apiFaAssetRevert(data) {
	return http.post('admin/v1/fa/asset/revert', data)
}

/**
 * 退库新增
 * @param {*} data
 * @returns
 */
export function apiFaAssetRevertAdd(data) {
	return http.post('admin/v1/fa/asset/revert/add', data)
}

/**
 * 退库详情
 * @param {*} data
 * @returns
 */
export function apiFaAssetRevertInfo(data) {
	return http.post('admin/v1/fa/asset/revert/get_info', data)
}
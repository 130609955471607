<!--
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-26 10:40:39
 * @LastEditors: 鱿鱼须 1519964028@qq.com
 * @LastEditTime: 2024-10-09 15:46:02
 * @FilePath: \dc.amin\src\components\dc\choose\StoreList.vue
 * @Description: 仓库列表
-->
<template>
	<el-select
		v-model="model"
		filterable
		remote
		reserve-keyword
		placeholder="搜索仓库"
		remote-show-suffix
		:remote-method="remoteMethod"
		:loading="loading"
		value-key="id"
		@focus="handelrList"
	>
		<el-option
			v-for="item in list"
			:key="item.id"
			:label="item.name"
			:value="props.attrs.isName ? { id: item.id, name: item.name } : item.id"
		/>
	</el-select>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { apiGetWmsWarehouseList } from '@/api/public'
const model = defineModel()

const loading = ref(false)
const list = ref([])
const props = defineProps({
	attrs: {
		type: Object,
		default: () => ({
			isName: false
		})
	}
})
const handelrList = async (query = '') => {
	loading.value = true
	const { data } = await apiGetWmsWarehouseList({
		page: 1,
		limit: 50,
		keyword: query
	})
	list.value = data.data
	loading.value = false
}

const remoteMethod = async (query) => {
	if (query) {
		handelrList(query)
	}
}

onMounted(() => {
	handelrList()
})
</script>

<style lang="scss" scoped></style>

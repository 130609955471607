'use strict'
/*
 * @Author: 鱿鱼须 1519964028@qq.com
 * @Date: 2024-08-09 14:15:09
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-08 10:50:28
 * @FilePath: \dc.amin\src\utils\tool.js
 */
import { ElMessage } from 'element-plus'

// 处理返回逻辑
// 在vue2中使用时请传出this值
export const handlerResult = (options = {}, that = {}) => {
  let defaultOptions = {
    data: {},
    success: () => { },
    fail: () => { },
    complete: () => { }
  }

  defaultOptions = {
    ...defaultOptions,
    ...options
  }

  if (options.data.code === 200) {
    ElMessage({
      message: defaultOptions.data.msg,
      type: 'success'
    })
    defaultOptions.success.call(that)
  } else {
    ElMessage({
      message: defaultOptions.data.msg,
      type: 'error'
    })
    defaultOptions.fail.call(that)
  }

  defaultOptions.complete.call(that)
}

// 等待函数
export const sleep = (t = 300) => {
  let timer
  return new Promise((resolve) => {
    timer = setTimeout(() => {
      clearTimeout(timer)
      resolve()
    }, t)
  })
}

// 只执行一次函数
export const once = (callback) => {
  let flag = true
  return function () {
    if (flag) {
      callback && callback()
      flag = false
    }
  }
}

// 节流函数
export const throttle = (fn, delay = 300) => {
  let timer = null
  return function (...args) {
    if (!timer) {
      timer = setTimeout(() => {
        fn()
        timer = null
      }, delay)
    }
  }
}

// 防抖函数
export const debounce = (fn, delay) => {
  let timer = null
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}


/**
 * 分组
 * @param {*} array 
 * @param {*} key 
 * @returns 
 */
export const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    // 使用 key 函数提取分组键，如果是字符串，则直接用作键名
    // 如果 key 是一个函数，则调用该函数获取键名
    const groupKey = typeof key === 'function' ? key(currentItem) : currentItem[key];

    // 初始化分组数组（如果尚未创建）
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    // 将当前项添加到对应分组
    result[groupKey].push(currentItem);
    return result;
  }, {});
};


/**
 * 三级联动反推选中
 * @param data 三级联动数据
 * @param id  选中项id/value
 * @param field  三级联动value/label返回字段
 * @param backType  返回类型，默认value，可选label
 * @return {*}
 * **/
export const getCascadeData = (data, id, field, backType) => {
  if (!data || !id)
    return []
  if (!field)
    field = ['value', 'label']
  if (!backType)
    backType = 'value'
  id = id.toString()
  let rtnData = []

  getCascadeNode(rtnData, data, [], id, field, backType)

  let a
  if (backType === 'label') {
    a = rtnData.join('-')
    return a
  } else {
    return rtnData
  }
}

function getCascadeNode(rtnData, data, cascade, id, field, backType) {
  if (!data || !id)
    return
  data.forEach((item, index) => {
    if (item[field[0]] == id && backType === 'value')
      rtnData.push(cascade.concat(id))
    if (item[field[0]] == id && backType === 'label')
      rtnData.push(cascade.concat(item[field[1]]))

    if (item['children'] && item['children'].length > 0) {
      getCascadeNode(rtnData, item['children'], cascade.concat(item[field[backType === 'value' ? 0 : 1]]), id, field, backType)
    }
  })
  return rtnData
}

<!--
 * @Description: 展示 图片 传递 batchNo 和 group
 * @Author: 琢磨先生
 * @Date: 2024-08-14 09:55:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-22 17:51:04
-->
<template>
	<div class="image__preview" v-loading="loading">
		<template v-if="!loading && srcList.length > 0">
			<div class="image_item" v-for="(url, index) in srcList" :key="url">
				<el-image
					:src="url"
					:zoom-rate="1.2"
					:max-scale="7"
					:min-scale="0.2"
					:preview-src-list="srcList"
					:initial-index="index"
					preview-teleported
					fit="cover"
				/>
			</div>
		</template>
		<el-empty
			v-if="!loading && srcList.length === 0"
			description="暂无图片"
			:image-size="50"
		></el-empty>
	</div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { apiGetFiles } from '@/api/file.js'

const props = defineProps({
	batchNo: {
		type: String,
		required: true,
		default: ''
	},
	group: {
		type: String,
		default: ''
	}
})
const loading = ref(false)
const srcList = ref([])

onMounted(() => {
	// console.log('aaa');
}),
	watch(
		() => props.batchNo,
		(newVal) => {
			if (newVal) {
				loading.value = true
				apiGetFiles(newVal, props.group)
					.then((res) => {
						if (res.code === 200 && res.data) {
							srcList.value = res.data.map((m) => m.file_url)
						}
					})
					.finally(() => {
						loading.value = false
					})
			}
		},
		{
			immediate: true
		}
	)
</script>

<style lang="scss" scoped>
.image__preview {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	width: 100%;
	flex-grow: 1;
	--size: 80px;
	min-height: var(--size);
}

.image__preview .image_item {
	width: var(--size);
	height: var(--size);
}

.el-image {
	width: 80px;
	height: 80px;
	border-radius: 5px;
	overflow: hidden;
}
.el-empty {
	padding: 0;
	--el-empty-description-margin-top: 2px;
}
</style>

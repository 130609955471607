<!--
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-26 20:42:37
 * @LastEditors: 鱿鱼须 1519964028@qq.com
 * @LastEditTime: 2024-10-19 19:11:26
 * @FilePath: \dc.amin\src\components\dc\choose\Shipper.vue
 * @Description: 货主组件
-->
<template>
	<el-select
		v-model="model"
		remote-show-suffix
		filterable
		remote
		:remote-method="remoteMethod"
		placeholder="请选择"
	>
		<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { apiGetWmsOwnerList } from '@/api/public'
const loading = ref(false)
const list = ref([])
const model = defineModel()

const handelrList = async (query = '') => {
	loading.value = true
	const { data } = await apiGetWmsOwnerList({
		page: 1,
		limit: 50,
		keyword: query
	})
	list.value = data.data
	loading.value = false
}
const remoteMethod = async (query) => {
	if (query) {
		handelrList(query)
	}
}

onMounted(() => {
	handelrList && handelrList()
})
</script>

<style lang="scss" scoped></style>

<!--
 * @Description: dialog 弹出表单 。如果要每行宽度 list 配置项添加属性 col：'width:100%'； 
 						图片上传、文件上传 list 的 multiple 属性 =true多文件，=false单文件，group 属性为分组；表单的batch_no必填

						展示时 mode='DISPLAY'
						<template #append></template> 附加尾部内容
 * @Author: 琢磨先生
 * @Version: 1.0
 * @Author: 琢磨先生
 * @Date: 2024-07-27 10:11:45
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-31 23:11:14
-->
<template>
	<el-drawer
		:close-on-click-modal="true"
		:close-on-press-escape="false"
		:append-to-body="true"
		:destroy-on-close="true"
		:draggable="true"
		v-bind="$attrs"
		:size="width"
	>
		<div v-loading="loading">
			<slot name="alert"></slot>
			<el-form :model="form" ref="formRef" v-bind="$attrs" v-if="mode === 'DESIGN'">
				<template v-for="item in list" :key="item">
					<template v-if="item.hide !== true">
						<FormItem :form="form" :item="item"></FormItem>
					</template>
				</template>
				<slot name="append"></slot>
			</el-form>
			<el-form v-if="mode === 'DISPLAY'" v-bind="$attrs">
				<template v-for="item in list" :key="item">
					<template v-if="item.hide !== true">
						<FormDisplayItem :form="form" :item="item"></FormDisplayItem>
					</template>
				</template>
			</el-form>
		</div>
		<template #footer>
			<slot name="footer">
				<el-button type="primary" @click="onConfirm" :loading="saving" v-if="mode === 'DESIGN'"
					>确定</el-button
				>
			</slot>
		</template>
	</el-drawer>
</template>

<script setup>
import { ref } from 'vue'

import UploadAvatar from '../upload/Avatar'
import UploadImage from '../upload/Image'
import UploadFile from '../upload/File'
import ChooseEmp from '../choose/Emp'
import ChoosePost from '../choose/Post'
import ChooseDept from '../choose/Dept'
import ChooseDeptEmp from '../choose/DeptEmp'
import ChooseSchool from '../choose/School'
import ChooseRole from '../choose/Role'
import ChooseGovjob from '../choose/GovJob'
import ChooseRegion from '../choose/Region'
import ChooseDocument from '../choose/Document'
import ChoosePeople from '../choose/People'
import ChooseActivity from '../choose/Activity'
import ChooseHotelRoom from '../choose/HotelRoom.vue'
import ChooseCurriculum from '../choose/Curriculum.vue'
import ChooseClass from '../choose/Class.vue'
import ChooseAssets from '../choose/Assets.vue'
import ChooseRegistration from '../choose/Registration.vue'
import InfoFile from '../info/File.vue'
import InfoImage from '../info/Image.vue'
import InfoAvatar from '../info/Avatar.vue'
import InfoEmp from '../info/Emp.vue'
import InfoDeptEmp from '../info/DeptEmps.vue'
import InfoActivity from '../info/Activity.vue'
import InfoClass from '../info/Class.vue'
import InfoDept from '../info/Dept.vue'
import InfoAssets from '../info/Assets.vue'
import InfoSchool from '../info/School.vue'
import InfoDocument from '../info/Document.vue'
import InfoRegistration from '../info/Registration.vue'
import GoodsType from '../choose/GoodsType.vue'
import StoreList from '../choose/StoreList.vue'
import VaultSrc from '../choose/VaultSrc.vue'
import Supplier from '../choose/Supplier.vue'
import Shipper from '../choose/Shipper.vue'
import Comp from '../choose/Comp.vue'
import Aisle from '../choose/Aisle.vue'
import ClassType from '../choose/ClassType.vue'
import FormItem from './FormItem.vue'
import FormDisplayItem from './FormDisplayItem.vue'
import WangEditor from './WangEditor.vue'

const formRef = ref()
const props = defineProps({
	//设计：DESIGN 展示：DISPLAY
	mode: {
		type: String,
		default: 'DESIGN'
	},
	saving: {
		type: Boolean,
		default: false
	},
	form: {
		type: Object,
		default: {}
	},
	/**
	 *  示例 {
	 * 			label:'',
	 * 			type:'' //组件类型 avatar头像 image图片 file文件附件 emp人员 post岗位 dept部门 deptemp school学校
	 * 					govjob公职类型 province省份 city省市 county省市县 document加油包资料 activity活动 hotelroom酒店房间 curriculum课程 class班级
	 * 		}
	 */
	list: {
		type: Array,
		default: []
	},
	/**
	 * 是否加载数据。修改、查看详情时 需要加载数据
	 */
	loading: {
		type: Boolean,
		default: false
	},

	/**
	 * 宽度
	 */
	width: {
		type: String,
		default: '600px'
	}
})

const emit = defineEmits(['submit'])

const onConfirm = () => {
	formRef.value.validate((valid) => {
		if (valid) {
			emit('submit', props.form)
		}
	})
}

// 时间禁用
const handlerDisableDate = (date, item) => {
	if (item.callback) {
		return item.callback(date)
	} else {
		return false
	}
}

/**
 * 人员资料显示
 */
function handlePreviewEmp(emps) {
	if (emps && Array.isArray(emps)) {
		return emps.map((m) => m.name).join('、')
	}
	return ''
}

function handlePreviewSelect(prop, value) {
	if (prop['options'] && Array.isArray(prop['options'])) {
		if (prop['multiple']) {
			if (value && Array.isArray(value)) {
				return value
					.map((m) => {
						return prop['options'].find((o) => o.value === m)?.label ?? ''
					})
					.join('、')
			} else {
				return ''
			}
		}
		return prop['options'].find((o) => o.value === value)?.label
	}
	return ''
}
</script>

<style scoped>
.el-form {
	display: flex;
	flex-wrap: wrap;
}

.el-form-item {
	width: 100%;
}
</style>

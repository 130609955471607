/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-08-08 17:41:07
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-31 22:01:45
 */
import http from '@/http/axios_init'
 
export default {
	/**
	 * 获取公共的系统设置
	 */
	 getPublicSetting() {
		return  http.get('admin/v1/public/sys_setting')
	}
}

<!--
 * @Description: 展示 附件 传递 batchNo 和 group
 * @Author: 琢磨先生
 * @Date: 2024-08-14 09:55:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-14 11:12:23
-->
<template>
	<div class="file__preview" v-loading="loading">
		<div class="file" v-for="item in dataList" :key="item.id">
			<div class="name">{{ item.name }}</div>
			<div class="size">{{ handleSize(item.size) }}</div>
			<div class="download">
				<el-link
					type="primary"
					:href="handleDownload(item)"
					:underline="false"
					download
					>下载</el-link
				>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { apiGetFiles } from '@/api/file.js'
import config from '@/config/config.js'
const props = defineProps({
	batchNo: {
		type: String,
		required: true,
		default: ''
	},
	group: {
		type: String,
		default: ''
	}
})
const loading = ref(false)
const dataList = ref([])

watch(
	() => props.batchNo,
	(newVal) => {
		if (newVal) {
			loading.value = true

			apiGetFiles(newVal, props.group)
				.then((res) => {
					if (res.code === 200 && res.data) {
						dataList.value = res.data
					}
				})
				.finally(() => {
					loading.value = false
				})
		}
	},
	{
		immediate: true
	}
)

/**
 * 文件转换
 * @param size
 */
function handleSize(size) {
	var num = Number(size)
	if (num >= 1024 * 1024 * 1024 * 1024) {
		return (num / 1024 / 1024 / 1024 / 1024).toFixed(2) + 'TB'
	} else if (num >= 1024 * 1024 * 1024) {
		return (num / 1024 / 1024 / 1024).toFixed(2) + 'GB'
	} else if (num >= 1024 * 1024) {
		return (num / 1024 / 1024).toFixed(2) + 'MB'
	} else if (num >= 1024) {
		return (num / 1024).toFixed(2) + 'KB'
	} else {
		return num + 'B'
	}
}

function handleDownload(item) {
    return item.file_url.replace('http://dcoaoss.yuandingcheng.com', config.OSS_DOWNLOAD)
}
</script>

<style lang="scss" scoped>
.file__preview {
	width: 100%;
	flex-grow: 1;
}

.file {
	display: flex;
	align-items: center;
	border-bottom: 1px dotted var(--el-border-color);

	.name {
		flex-grow: 1;
	}

	.size {
		width: 120px;
	}
}
</style>

import config from "@/config/config";

const install = (app) => {
  /**
   * 全局配置
   */
  app.config.globalProperties.$cfg = config;
};

export default { install };

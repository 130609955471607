<!--
 * @Description: 加油包候选人选择  v-model 传入的值是数组，示例：[{
						id: 2,
						title: '临沂地区',
					}] ，:multiple="true" 是否多选，默认true
 * @Author: 琢磨先生
 * @Date: 2024-08-23 08:52:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-23 19:47:43
-->
<template>
  <span @click.stop="onOpen">
    <slot name="reference">
      <el-button plain type="default" icon="plus" size="small">选择候选人</el-button>
    </slot>
  </span>
  <slot>
    <div class="choose_wrp">
      <div class="item" v-for="item in model" :key="item">
        <el-avatar :size="25">
          <el-icon>
            <!-- <Document /> -->
            <User />
          </el-icon>
        </el-avatar>
        <el-text size="small">{{ item.name }}</el-text>
        <el-icon class="close" @click.stop="onRemoveItem(item)">
          <Close />
        </el-icon>
      </div>
    </div>
  </slot>

  <el-dialog title="请选择" v-model="visible" :close-on-click-modal="false" :close-on-press-escape="false" width="800px"
    :append-to-body="true">
    <el-row>
      <el-col :span="13">
        <el-input v-model="query.keywords" placeholder="请输入名称" clearable @input="onSearch"></el-input>
        <div class="body">
          <el-table ref="tableRef" v-loading="loading" :data="tableData.data" border :row-key="(row) => row.id"
            :highlight-current-row="true" :default-expand-all="false" style="height: 100%"
            @selection-change="onSelectionChange">
            <el-table-column label="" type="selection" reserve-selection fixed="left"></el-table-column>
            <el-table-column label="姓名" prop="name" min-width="120"></el-table-column>
            <el-table-column label="电话号码" prop="mobile_phone" width="180"></el-table-column>
            <!-- <el-table-column label="邮箱" prop="email" width="200"></el-table-column> -->
          </el-table>
        </div>
      </el-col>
      <el-col :span="11">
        <div class="header">
          <span>已选 {{ checkList.length }} 项</span>
          <el-button type="danger" link size="small" @click="onClean">清空</el-button>
        </div>
        <div class="checked body">
          <el-scrollbar v-if="checkList.length">
            <div class="select_item" v-for="item in checkList" :key="item.id">
              <div class="flex-row item-center flex-1">
                <el-avatar :size="30" style="margin-right: 5px">
                  <el-icon>
                    <User />
                  </el-icon>
                </el-avatar>
                <div class="flex-1">
                  <div>{{ item.name }}</div>
                  <!-- <div class="dept_name">{{ item.dept_name }}</div> -->
                </div>
              </div>
              <el-icon @click="onRemove(item)">
                <Close />
              </el-icon>
            </div>
          </el-scrollbar>
          <el-empty v-if="checkList.length === 0" description="暂无数据"></el-empty>
        </div>
      </el-col>
    </el-row>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import { apiGetPeopleList } from '@/api/public'
const model = defineModel({ type: Array, default: [] })
const props = defineProps({
  /**
   * 多选 默认true
   */
  multiple: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['change'])
const visible = ref(false)
const loading = ref(false)
const tableRef = ref(null)
const query = ref({
  page: 1,
  limit: 20,
  order: {
    sort: '',
    field_name: ''
  }
})
const checkList = ref([])
const tableData = ref({
  data: []
})

watch(
  () => model.value,
  (val) => {
    console.log(val)
    if (val) {
      val.forEach((item) => {
        if (!checkList.value.find((o) => o.id == item.id)) {
          checkList.value.push(item)
        }
      })
    }
  },
  {
    deep: true,
    immediate: true
  }
)

onMounted(() => {
  loadData()
})

/**
 * 加载数据
 */
function loadData() {
  loading.value = true
  apiGetPeopleList(query.value)
    .then((res) => {
      if (res.code === 200) {
        tableData.value = res.data
        if (tableRef.value) {
          nextTick(() => {
            tableData.value.data.forEach((item) => {
              tableRef.value.toggleRowSelection(
                item,
                checkList.value.find((o) => o.id == item.id) ? true : false
              )
            })
          })
        }
      }
    })
    .finally(() => (loading.value = false))
}

/**
 * 打开 默认选中
 */
const onOpen = () => {
  visible.value = true
  nextTick(() => {
    model.value.forEach((item) => {
      if (!checkList.value.find((o) => o.id == item.id)) {
        checkList.value.push({ item })
      }
    })
    tableData.value.data.forEach((item) => {
      tableRef.value.toggleRowSelection(
        item,
        checkList.value.find((o) => o.id == item.id) ? true : false
      )
    })
  })
}

/**
 * 查询
 */
function onSearch() {
  query.value.page = 1
  loadData()
}

/**
 * 已选择的节点
 * @param nodes
 */
function onSelectionChange(nodes) {
  if (nodes.length > 0) {

    let arr = [];
    nodes.forEach((item) => {
      arr.push(item);
      checkList.value = arr;
    })
  } else {
    checkList.value = [];
  }

}

/**
 * 移除已选择的
 * @param item
 */
function onRemoveItem(item) {
  checkList.value = checkList.value.filter((m) => m.id !== item.id)
  onConfirm()
  // nextTick(() => {
  // 	tableRef.value.toggleRowSelection(
  // 		tableData.value.data.find((o) => o.id == item.id),
  // 		false
  // 	)
  // })
}

/**
 * 移除已选择
 */
function onClean() {
  checkList.value = []
  tableRef.value.clearSelection()
}

/**
 * 移除
 * @param item
 */
function onRemove(item) {
  checkList.value = checkList.value.filter((m) => m.id !== item.id)
  tableRef.value.toggleRowSelection(
    tableData.value.data.find((o) => o.id == item.id),
    false
  )
}

/**
 * 确定选择
 */
function onConfirm() {
  let list = checkList.value.map((item) => {
    return {
      id: item.id,
      name: item.name
    }
  })
  model.value = list
  emit('change', list)
  visible.value = false
}
</script>

<style lang="scss" scoped>
.el-col {
  height: 400px;
  border: 1px solid var(--el-border-color);
  display: flex;
  flex-direction: column;
}

.el-col:first-child {
  border-right: none;
}

.el-col .el-input {
  padding: 5px;
}

.header {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--el-border-color);
}

.body {
  flex-grow: 1;
  height: 1px;
}

.checked.body .el-scrollbar {
  padding: 10px;
}

.select_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 4px;

  &:hover {
    background-color: #f5f7fa;
  }
}

.select_item .name {
  font-size: 12px;
  color: #c0c4cc;
}

// 已选择
.choose_wrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.choose_wrp .item {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 4px 5px;
  background-color: #f5f6f6;
  height: 30px;
  border-radius: 15px;
}

.choose_wrp .close.el-icon {
  cursor: pointer;
  padding: 2px;
  margin-left: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.choose_wrp .close.el-icon:hover {
  background-color: #999;
}

.choose_wrp .item .el-avatar span {
  position: absolute;
  display: flex;
  align-items: center;
}

.choose_wrp .el-text {
  margin-left: 3px;
}
</style>

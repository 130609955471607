<!--
 * @Description: 选择人员集合 包括 emp，dept，post， role
 * @Author: 琢磨先生
 * @Date: 2024-08-21 09:55:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-21 19:02:19
-->
<template>
	<span @click.stop="onOpen">
		<slot name="reference">
			<el-button plain type="primary" icon="plus" size="small">选择人员/部门/岗位/角色</el-button>
		</slot>
	</span>
	<slot>
		<div class="choose_items">
			<div class="item" v-for="item in model" :key="item">
				<el-avatar
					:size="25"
					:src="item.avatar_url"
					:class="{
						emp: item.type === 'emp',
						dept: item.type === 'dept',
						role: item.type === 'role',
						post: item.type === 'post'
					}"
				>
					<el-icon><dc-icon name="dept" v-if="item.type === 'dept'"></dc-icon></el-icon>
					<span v-if="item.type === 'emp'">{{ item.name.substring(0, 1) }}</span>
					<span v-if="item.type === 'post'">岗</span>
					<span v-if="item.type === 'role'">角</span>
				</el-avatar>
				<el-text size="small">{{ item.name }}</el-text>
				<el-icon class="close" @click.stop="onRemoveItem(item)">
					<Close />
				</el-icon>
			</div>
		</div>
	</slot>
	<el-dialog
		title="人员/部门/岗位/角色选择"
		v-model="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="900px"
		:append-to-body="true"
	>
		<el-row>
			<el-col :span="14">
				<!-- <el-input v-model="q" placeholder="请输入人员/部门名称"></el-input> -->
				<el-tabs v-model="activityTabName">
					<el-tab-pane name="dept" label="部门" v-loading="loading">
						<el-scrollbar>
							<el-tree
								ref="deptTreeRef"
								:data="deptList"
								:props="{ label: 'name' }"
								:default-expanded-keys="expandedKeys"
								show-checkbox
								:check-strictly="true"
								node-key="id"
								:expand-on-click-node="true"
								:check-on-click-node="false"
								@check-change="deptCheckChange"
							></el-tree>
						</el-scrollbar>
					</el-tab-pane>
					<el-tab-pane name="emp" label="人员">
						<div class="flex-row" style="width: 100%; height: 100%">
							<div
								style="border-right: 1px solid var(--el-border-color); height: 100%; flex-grow: 1"
							>
								<el-scrollbar>
									<el-tree
										:data="deptList"
										:props="{ label: 'name' }"
										:default-expanded-keys="expandedKeys"
										node-key="id"
										:expand-on-click-node="false"
										:check-on-click-node="false"
										@node-click="empDeptNodeClick"
									></el-tree>
								</el-scrollbar>
							</div>
							<div style="width: 200px" v-loading="loading">
								<el-scrollbar>
									<div v-for="emp in empList" :key="emp.id" style="padding: 0 10px">
										<el-checkbox v-model="emp.is_checked" @change="onEmpCheckChange">
											<div class="flex-row item-center">
												<el-avatar :size="20" :src="emp.avatar_url" style="margin-right: 5px"
													>{{}}</el-avatar
												>
												{{ emp.name }}
											</div>
										</el-checkbox>
									</div>
								</el-scrollbar>
								<el-empty v-if="empList.length === 0 && !loading"></el-empty>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane name="post" label="岗位">
						<div class="flex-row" style="width: 100%; height: 100%">
							<div
								style="border-right: 1px solid var(--el-border-color); height: 100%; flex-grow: 1"
							>
								<el-scrollbar>
									<el-tree
										:data="deptList"
										:props="{ label: 'name' }"
										:default-expanded-keys="expandedKeys"
										node-key="id"
										:expand-on-click-node="false"
										:check-on-click-node="false"
										@node-click="postDeptNodeClick"
									></el-tree>
								</el-scrollbar>
							</div>
							<div style="width: 200px" v-loading="loading">
								<el-scrollbar v-if="postList.length > 0 && !loading">
									<div v-for="emp in postList" :key="emp.id" style="padding: 0 10px">
										<el-checkbox v-model="emp.is_checked" @change="onPostCheckChange">
											<div class="flex-row item-center">
												<el-avatar :size="20" :src="emp.avatar_url" style="margin-right: 5px"
													>{{}}</el-avatar
												>
												{{ emp.name }}
											</div>
										</el-checkbox>
									</div>
								</el-scrollbar>
								<el-empty v-if="postList.length === 0 && !loading"></el-empty>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane name="role" label="角色">
						<el-scrollbar>
							<el-tree
								:data="roleList"
								:props="{ label: 'name' }"
								:default-expanded-keys="roleExpandedKeys"
								show-checkbox
								:check-strictly="true"
								node-key="id"
								ref="roleTreeRef"
								:expand-on-click-node="true"
								:check-on-click-node="false"
								@check-change="roleCheckChange"
							>
								<template #default="{ node, data }">
									<div class="node" :class="{ father: data.father_id === 0 }">
										<el-avatar v-if="data.father_id > 0" :size="20" style="margin-right: 5px">
											<el-icon :size="12"> <User /> </el-icon
										></el-avatar>
										<span>{{ node.label }}</span>
									</div>
								</template>
							</el-tree>
						</el-scrollbar>
					</el-tab-pane>
				</el-tabs>
			</el-col>
			<el-col :span="10" class="checked_box">
				<div class="header">
					<span>已选 {{ checkedList.length }} 项</span>
					<el-button type="danger" link size="small" @click="onClean">清空</el-button>
				</div>
				<div class="body">
					<el-scrollbar v-if="checkedList.length">
						<div class="checked_item" v-for="item in checkedList" :key="item.id">
							<div class="flex-row item-center flex-1">
								<el-avatar
									:size="25"
									:src="item.avatar_url"
									:class="{
										emp: item.type === 'emp',
										dept: item.type === 'dept',
										role: item.type === 'role',
										post: item.type === 'post'
									}"
									style="margin-right: 5px"
								>
									<el-icon><dc-icon name="dept" v-if="item.type === 'dept'"></dc-icon></el-icon>
									<span v-if="item.type === 'emp'">员</span>
									<span v-if="item.type === 'post'">岗</span>
									<span v-if="item.type === 'role'">角</span>
								</el-avatar>
								<div class="info flex-1">
									<div>{{ item.name }}</div>
									<div class="dept_name">{{ item.dept_name }}</div>
								</div>
							</div>
							<el-icon @click="onRemove(item)">
								<Close />
							</el-icon>
						</div>
					</el-scrollbar>
					<el-empty v-if="checkedList.length === 0" description="暂无数据"></el-empty>
				</div>
			</el-col>
		</el-row>
		<template #footer>
			<el-button type="primary" @click="onConfirm">确定</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from 'vue'
import { nanoid } from 'nanoid'
import { getDepts, getEmps, getRoles, apiGetPost } from '@/api/public'
const model = defineModel({ type: Array, default: [] })
const props = defineProps({
	// list: {
	// 	type: Array,
	// 	default: []
	// }
})
const emit = defineEmits(['change'])
// const treeRef = ref()
const deptList = ref([])
const empList = ref([]) //
const empCheckList = ref([]) //已经选择的emp
const roleList = ref([])
const checkedList = ref([]) //所有选择的数据
const postList = ref([])
const postCheckList = ref([])
const expandedKeys = ref([])
const roleExpandedKeys = ref([])
const loading = ref(true)
const visible = ref(false)
const q = ref('')
const activityTabName = ref('dept')
const deptTreeRef = ref()
const roleTreeRef = ref()
watch(
	() => model.value,
	(newValue) => {
		if (newValue && Array.isArray(newValue)) {
			newValue.forEach((item) => {
				if (!checkedList.value.find((o) => o.id == item.id && o.type === item.type)) {
					checkedList.value.push(item)
				}
				if (item.type === 'emp') {
					if (!empCheckList.value.find((o) => o.id == item.id)) {
						empCheckList.value.push(item)
					}
					var emp = empList.value.find((o) => o.id == item.id)
					if (emp) {
						emp.is_checked = true
					}
				}
				if (item.type === 'post') {
					if (!postCheckList.value.find((o) => o.id == item.id && o.type === item.type)) {
						postCheckList.value.push(item)
					}
					var post = postList.value.find((o) => o.id == item.id)
					if (post) {
						post.is_checked = true
					}
				}
			})
		}
	},
	{
		immediate: true,
		deep: true
	}
)

getDepts()
	.then((res) => {
		if (res.code === 200 && res.data) {
			deptList.value = res.data
			if (res.data.length) {
				expandedKeys.value.push(res.data[0].id)
			}
		}
	})
	.finally(() => (loading.value = false))

/**
 * 获取角色
 */
getRoles().then((res) => {
	if (res.code === 200 && res.data) {
		roleList.value = res.data
		if (res.data.length) {
			roleExpandedKeys.value.push(res.data[0].id)
		}
	}
})

/**
 * 人员部门树节点点击
 * @param item
 */
function empDeptNodeClick(item) {
	loading.value = true
	getEmps(item.id)
		.then((res) => {
			if (res.code === 200 && res.data) {
				empList.value = res.data
				empList.value.forEach((item) => {
					item.is_checked = empCheckList.value.find((o) => o.id == item.id) ? true : false
				})
			}
		})
		.finally(() => {
			loading.value = false
		})
}

/**
 * 岗位部门点击
 * @param item
 */
function postDeptNodeClick(item) {
	loading.value = true
	apiGetPost([item.id])
		.then((res) => {
			if (res.code === 200 && res.data) {
				postList.value = res.data
				postList.value.forEach((item) => {
					item.is_checked = postCheckList.value.find((o) => o.id == item.id) ? true : false
				})
			}
		})
		.finally(() => {
			loading.value = false
		})
}

/**
 * 人员选择更改
 */
function onEmpCheckChange() {
	empList.value.forEach((o) => {
		if (o.is_checked) {
			if (!empCheckList.value.find((x) => x.id == o.id)) {
				empCheckList.value.push(o)
			}
		} else {
			empCheckList.value = empCheckList.value.filter((x) => x.id != o.id)
		}
	})

	empCheckList.value.forEach((item) => {
		if (!checkedList.value.find((x) => x.id == item.id && x.type === 'emp')) {
			checkedList.value.push({ ...item, type: 'emp' })
		}
	})

	checkedList.value.forEach((item) => {
		if (item.type == 'emp') {
			if (!empCheckList.value.find((o) => o.id == item.id)) {
				checkedList.value = checkedList.value.filter(
					(o) => (o.id != item.id && o.type === 'emp') || o.type != 'emp'
				)
			}
		}
	})
}

/**
 * 岗位选择更改
 */
function onPostCheckChange() {
	postList.value.forEach((o) => {
		if (o.is_checked) {
			if (!postCheckList.value.find((x) => x.id == o.id)) {
				postCheckList.value.push(o)
			}
		} else {
			postCheckList.value = postCheckList.value.filter((x) => x.id != o.id)
		}
	})

	postCheckList.value.forEach((item) => {
		if (!checkedList.value.find((x) => x.id == item.id && x.type === 'post')) {
			checkedList.value.push({ ...item, type: 'post' })
		}
	})

	checkedList.value.forEach((item) => {
		if (item.type === 'post') {
			if (!postCheckList.value.find((o) => o.id == item.id)) {
				postCheckList.value = checkedList.value.filter(
					(o) => (o.id != item.id && o.type === 'post') || o.type != 'post'
				)
			}
		}
	})
}

/**
 * 部门选择变化
 */
function deptCheckChange(item, checked) {
	if (checked) {
		if (!checkedList.value.find((o) => o.type === 'dept' && o.id == item.id)) {
			checkedList.value.push({
				id: item.id,
				type: 'dept',
				name: item.name
			})
		}
	} else {
		checkedList.value = checkedList.value.filter((o) => (o.id != item.id && o.type === 'dept') || o.type != 'dept')
	}
}

/**
 * 角色选择变化
 */
function roleCheckChange(item, checked) {
	if (checked) {
		if (!checkedList.value.find((o) => o.type === 'role' && o.id == item.id)) {
			checkedList.value.push({
				id: item.id,
				type: 'role',
				name: item.name
			})
		}
	} else {
		checkedList.value = checkedList.value.filter((o) => o.id != item.id && o.type === 'role')
	}
}

/**
 * 移除选中的人员
 * @param item
 */
function onRemove(item) {
	var list = [...checkedList.value]
	if (item.type === 'emp') {
		empCheckList.value = empCheckList.value.filter((o) => o.id != item.id)
		empList.value.forEach((o) => {
			if (o.id == item.id) {
				o.is_checked = false
			}
		})
	} else if (item.type === 'post') {
		postCheckList.value = postCheckList.value.filter((o) => o.id != item.id)
		postList.value.forEach((o) => {
			if (o.id == item.id) {
				o.is_checked = false
			}
		})
	} else if (item.type === 'dept') {
		var ids = list.filter((o) => o.id != item.id && o.type === 'dept').map((m) => m.id)
		nextTick(() => {
			deptTreeRef.value.setCheckedKeys(ids)
		})
	} else if (item.type === 'role') {
		roleTreeRef.value.setCheckedKeys(
			list.filter((o) => o.id != item.id && o.type === 'role').map((m) => m.id)
		)
	}
	checkedList.value = checkedList.value.filter(
		(o) => (o.id != item.id && o.type === item.type) || o.type != item.type
	)
}

/**
 * 清空选中
 */
function onClean() {
	checkedList.value = []
	setCheck()
}

/**
 * 确认选择
 */
function onConfirm() {
	let list = checkedList.value.map((item) => {
		return {
			id: item.id,
			name: item.name,
			type: item.type,
			avatar_url: item.avatar_url
		}
	})
	model.value = list
	emit('change', list)
	visible.value = false
}

const onOpen = () => {
	visible.value = true
	if (model.value.length) {
		checkedList.value = [...model.value]
	}
	setCheck()
}

/**
 * 设置选中
 */
function setCheck() {
	nextTick(() => {
		if (deptTreeRef.value) {
			deptTreeRef.value.setCheckedKeys(
				checkedList.value.filter((o) => o.type === 'dept').map((o) => o.id)
			)
		}
		if (roleTreeRef.value) {
			roleTreeRef.value.setCheckedKeys(
				checkedList.value.filter((o) => o.type === 'role').map((o) => o.id)
			)
		}
		empList.value.forEach((item) => {
			item.is_checked = empCheckList.value.find((o) => o.id == item.id) ? true : false
		})
		postList.value.forEach((item) => {
			item.is_checked = postCheckList.value.find((o) => o.id == item.id) ? true : false
		})
	})
}
/**
 * 删除model 的项
 */
function onRemoveItem(item) {
	checkedList.value = checkedList.value.filter((o) => !(o.id == item.id && o.type === item.type))
	if (item.type === 'emp') {
		empCheckList.value = empCheckList.value.filter((o) => o.id != item.id)
	}
	if (item.type === 'post') {
		postCheckList.value = postCheckList.value.filter((o) => o.id != item.id)
	}
	onConfirm()
	setCheck()
}
</script>

<style lang="scss" scoped>
.el-col {
	height: 500px;
	border: 1px solid var(--el-border-color);
	display: flex;
	flex-direction: column;
}

.el-col:first-child {
	border-right: none;
}

.el-col .el-tabs {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 1px;
}

:deep(.el-tabs .el-tabs__header) {
	margin: 0;
}

:deep(.el-col .el-tabs__content) {
	flex-grow: 1;
	height: 100%;
}

.el-tab-pane {
	flex-grow: 1;
	height: 100%;
}

.el-col .el-input {
	padding: 5px;
}

:deep(.el-tree) {
	--el-tree-node-content-height: 34px;
}

:deep(.el-tree-node__content:has(.father) > .el-checkbox) {
	display: none;
}

// 头像背景
.el-avatar.emp {
	background: #95d475;
}
.el-avatar.dept {
	background: #79bbff;
}
.el-avatar.post {
	background: #e6a23c;
}
.el-avatar.role {
	background: #f89898;
}
.el-avatar span {
	position: absolute;
	display: flex;
	align-items: center;
	font-size: 12px;
}

// 已选择的box
.checked_box .body {
	flex-grow: 1;
	height: 1px;
}

.checked_box .header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 10px;
	justify-content: space-between;
	border-bottom: 1px solid var(--el-border-color);
}

.checked_box .body .el-scrollbar {
	padding: 10px;
}

.checked_box .body .checked_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 8px 10px;
	border-radius: 4px;

	&:hover {
		background-color: #f5f7fa;
	}

	.dept_name {
		font-size: 12px;
		color: #c0c4cc;
	}
}

// 确认结果展示
.choose_items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;
	width: 100%;
}

.choose_items .item {
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 3px 5px;
	border-radius: 15px;
	background-color: #f5f6f6;
	font-size: 14px;
	line-height: 14px;
}

.choose_items .close.el-icon {
	cursor: pointer;
	padding: 2px;
	margin-left: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.choose_items .close.el-icon:hover {
	background-color: #999;
}

.choose_items .el-text {
	margin-left: 3px;
}
</style>

<template>
	<el-cascader
		v-model="model"
		:options="list"
		clearable
		:props="{
			label: 'name',
			value: 'id',
			children: 'children',
			emitPath: false
		}"
		v-bind="$attrs"
	/>
</template>

<script setup>
import { apiFaTypeList } from '@/api/fixedAssets'
import { onMounted, ref } from 'vue'

let model = defineModel()
model.value = ''
const list = ref([])
onMounted(() => {
	apiFaTypeList()
		.then(({ data }) => {
			list.value = data
		})
		.catch((error) => {
			console.error('获取数据失败:', error)
		})
})
</script>

<style lang="scss" scoped></style>

/*
 * @Description:crm客户管理菜单配置
 * @Author: 琢磨先生
 * @Date: 2024-07-15 12:26:43
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-18 09:27:15
 */
export default {
	path: '/',
	icon: '',
	meta: {
		title: '客户管理'
	},
	children: [
		{
			path: '/',
			icon: '',
			meta: {
				title: '线索管理'
			},
			children: [
				{
					path: '/crm/lead',
					icon: '',
					component: () => import('@/views/crm/lead'),
					meta: {
						title: '线索'
					},
					power: 'admin/v1/kq/plan'
				},
				{
					path: '/crm/lead/pool',
					icon: '',
					component: () => import('@/views/crm/lead/pool'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '公海'
					}
				},
				{
					path: '/crm/lead/recycle',
					icon: '',
					component: () => import('@/views/crm/lead/recycle'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '回收站'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '意向学员'
			},
			children: [
				{
					path: '/crm/student',
					icon: '',
					component: () => import('@/views/crm/student'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '意向学员'
					}
				},
				{
					path: '/crm/student/pool',
					icon: '',
					component: () => import('@/views/crm/student/pool'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '资源池'
					}
				},
				{
					path: '/crm/student/recycle',
					icon: '',
					component: () => import('@/views/crm/student/recycle'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '回收站'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '活动管理'
			},
			children: [
				{
					path: '/crm/activity',
					icon: '',
					component: () => import('@/views/crm/activity/list'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '活动列表'
					}
				},
				{
					path: '/crm/activity/channel',
					icon: '',
					component: () => import('@/views/crm/activity/channel'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '活动渠道'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '加油包'
			},
			children: [
				{
					path: '/crm/refuel/document',
					icon: '',
					component: () => import('@/views/crm/refuel/document'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '资料管理'
					}
				}
			]
		},
		{
			path: '/',
			icon: '',
			meta: {
				title: '管理配置'
			},
			children: [
				{
					path: '/crm/method',
					icon: '',
					component: () => import('@/views/crm/method'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '跟进方式'
					}
				},
				{
					path: '/crm/tag',
					icon: '',
					component: () => import('@/views/crm/tag'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '标签管理'
					}
				},
				{
					path: '/crm/source',
					icon: '',
					component: () => import('@/views/crm/source'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '来源管理'
					}
				}
			]
		}
	]
}

<!--
 * @Description: v-model 传入的值是数组，示例：[{
						id: 2,
						name: '临沂地区',
						parent_id: 1
					}] ，:multiple="true" 是否多选，默认true
					FullName：是否显示全路径，默认false
 * @Author: 琢磨先生
 * @Date: 2024-08-09 14:59:46
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-09 14:47:59
-->
<template>
	<span @click.stop="onOpen">
		<slot name="reference">
			<el-button plain type="primary" icon="plus" size="small">选择部门</el-button>
		</slot>
	</span>
	<slot>
		<div class="choose_items">
			<div class="item" v-for="item in model" :key="item">
				<el-avatar :size="25" :src="item.avatar_url">
					<el-icon><dc-icon name="dept"></dc-icon></el-icon>
				</el-avatar>
				<el-text size="small">{{ FullName ? item.full_dept_name : item.name }}</el-text>
				<el-icon class="close" @click.stop="onRemoveItem(item)">
					<Close />
				</el-icon>
			</div>
		</div>
	</slot>
	<el-dialog title="请选择" v-model="visible" :destroy-on-close="true" :close-on-click-modal="false"
		:close-on-press-escape="false" width="700px" :append-to-body="true">
		<el-row>
			<el-col :span="13">
				<el-input v-model="q" placeholder="请输入名称" clearable></el-input>
				<div class="body">
					<el-scrollbar>
						<el-tree :data="deptTreeData" :props="{ label: 'name' }" :default-expanded-keys="expandedKeys" :multiple="multiple"
							:filter-node-method="filterNode" show-checkbox :check-strictly="true" node-key="id"
							ref="treeRef" :expand-on-click-node="true" :check-on-click-node="false"
							@check-change="handleCheckChange"></el-tree>
					</el-scrollbar>
				</div>
			</el-col>
			<el-col :span="11">
				<div class="header">
					<span>已选 {{ checkList.length }} 项</span>
					<el-button type="danger" link size="small" @click="onClean">清空</el-button>
				</div>
				<div class="checked body">
					<el-scrollbar v-if="checkList.length">
						<div class="dept_item" v-for="item in checkList" :key="item.id">
							<div class="flex-row item-center flex-1">
								<el-avatar :size="30" :src="item.avatar_url"
									style="margin-right: 5px; background: #79bbff">部</el-avatar>
								<div class="dept flex-1">
									<div>{{ item.name }}</div>
									<div class="dept_name">{{ item.dept_name }}</div>
								</div>
							</div>
							<el-icon @click="onRemove(item)">
								<Close />
							</el-icon>
						</div>
					</el-scrollbar>
					<el-empty v-if="checkList.length === 0" description="暂无数据"></el-empty>
				</div>
			</el-col>
		</el-row>
		<template #footer>
			<el-button type="primary" @click="onConfirm">确定</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from 'vue'
import { getDepts } from '@/api/public'
const model = defineModel({ type: Array, default: [] })
const props = defineProps({
	/**
	 * 多选？
	 */
	multiple: {
		type: Boolean,
		default: true
	},
	/**
	 * 是否显示全名
	 */
	FullName: {
		type: Boolean,
		default: false
	}
})
const emit = defineEmits(['change'])

const visible = ref(false)
const q = ref('')
const deptTreeData = ref([])
const treeRef = ref(null)
const checkList = ref([])
const expandedKeys = ref([])
const tempList = ref([])//所有的部门列表

watch(q, (val) => {
	treeRef.value.filter(val)
})


/**
 * 获取部门列表
 */
getDepts().then((res) => {
	if (res.code === 200 && res.data) {
		res.data.forEach(item => {
			recursion(item, null)
		});
		deptTreeData.value = res.data
		if (res.data.length) {
			expandedKeys.value.push(res.data[0].id)
		}
		if (model.value) {
			model.value.forEach(m => {
				var dept = tempList.value.find(c => c.id === m.id)
				if (dept) {
					m.full_dept_name = dept.full_dept_name
				}
			})
		}
	}
})

/**
 * 递归处理
 */
function recursion(item, father) {
	if (father) {
		item.full_dept_name = father.full_dept_name + '/' + item.name
	} else {
		item.full_dept_name = item.name
	}
	tempList.value.push({
		id: item.id,
		name: item.name,
		full_dept_name: item.full_dept_name,
	})


	if (item.children && item.children.length) {
		item.children.forEach(c => {
			recursion(c, item)
		})
	}
}

/**
 * 筛选
 * @param value
 * @param data
 */
const filterNode = (value, data) => {
	if (!value) return true
	return data.name.indexOf(value) !== -1
}

function handleCheckChange(item, checked) {
	if (!props.multiple) {
		if (checked) {
			treeRef.value.setCheckedKeys([])
			treeRef.value.setChecked(item.id, true)
		}
	}
	checkList.value = treeRef.value.getCheckedNodes()
}

function onClean() {
	checkList.value = []
	treeRef.value.setCheckedKeys([])
}

/**
 * 打开 默认选中
 */
const onOpen = () => {
	visible.value = true
	nextTick(() => {
		treeRef.value.setCheckedKeys(model.value.map((m) => m.id))
	})
}
const onConfirm = () => {
	var list = [...checkList.value]
	model.value = list
	emit('change', list)
	visible.value = false
}

const onRemove = (item) => {
	treeRef.value.setChecked(item.id, false)
}

/**
 * 移除model的项
 */
function onRemoveItem(item) {
	checkList.value = checkList.value.filter((c) => c.id !== item.id)
	onConfirm()
}
</script>

<style lang="scss" scoped>
.el-col {
	height: 400px;
	border: 1px solid var(--el-border-color);
	display: flex;
	flex-direction: column;
}

.el-col:first-child {
	border-right: none;
}

.el-col .el-input {
	padding: 5px;
}

.header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 10px;
	justify-content: space-between;
	border-bottom: 1px solid var(--el-border-color);
}

.body {
	flex-grow: 1;
	height: 1px;
}

.checked.body .el-scrollbar {
	padding: 10px;
}

.dept_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 5px 10px;
	border-radius: 4px;

	&:hover {
		background-color: #f5f7fa;
	}
}

.dept_item .dept_name {
	font-size: 12px;
	color: #c0c4cc;
}

// 已选择
.choose_items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;
	width: 100%;
}

.choose_items .item {
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 3px 5px;
	border-radius: 15px;
	background-color: #f5f6f6;
	font-size: 14px;
	line-height: 14px;
}

.choose_items .close.el-icon {
	cursor: pointer;
	padding: 2px;
	margin-left: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.choose_items .close.el-icon:hover {
	background-color: #999;
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar span {
	position: absolute;
	display: flex;
	align-items: center;
}

.choose_items .el-text {
	margin-left: 3px;
}
</style>

/*
 * @Author: Sky
 * @Date: 2024-09-27 19:06:29
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-15 11:10:36
 * @Description: desc
 */
/*
 * @Description: 公共接口（需要登录）
 * @Author: 琢磨先生
 * @Date: 2024-08-09 15:11:29
 * @LastEditors: GW 1519964028@.qq.com
 */
import http from '@/http/axios_init'

/**
 * 获取最新考勤班次
 */
export function getKqClass() {
	return http.get('admin/v1/public/kq_class_list')
}
/**
 * 获取请假类型列表
 */
export function getLeaveTypes() {
	return http.get('admin/v1/public/leave_type_list')
}

/**
 * 获取部门列表
 * @returns
 */
export function getDepts(keywords = '') {
	return http.get('admin/v1/public/oa_dept_list?keywords=' + keywords)
}

/**
 * 获取员工列表
 * @param {*} deptId 员工id
 */
export function getEmps(deptId = '', k = '') {
	return http.get(`admin/v1/public/oa_emp_list?dept_id=${deptId}&keywords=${k}`)
}

/**
 * 获取角色列表，2级
 * @param {*} keywords
 * @returns
 */
export function getRoles(keywords = '') {
	return http.post('admin/v1/oa/role/index', {
		keywords: keywords
	})
}

/**
 * 获取岗位列表
 * @param {*} dept_id  部门id集合
 * @returns
 */
export function apiGetPost(dept_id = []) {
	return http.post('admin/v1/public/oa_post_list', { dept_id })
}

/**
 * 获取管理员列表
 * @returns
 */
export function apiEmpList(data) {
	return http({
		method: 'POST',
		url: 'admin/v1/public/oa_emp_list',
		data
	})
}

/**
 * 获取校区tree
 * @returns
 */
export function apiGetSchool() {
	return http.get('admin/v1/public/oa_dept_list?type=10')
}

/**
 * crm学员来源
 * @returns
 */
export function apiGetCrmSource() {
	return http.get('admin/v1/crm/source')
}

/**
 * 活动渠道列表
 * @returns
 */
export function apiGetActivityChannel() {
	return http.post('admin/v1/crm/activity/channel')
}

/**
 * 课程列表
 */
export const apiCurrList = (params) => {
	return http({
		url: 'admin/v1/public/curriculum_list',
		method: 'GET',
		params
	})
}

/**
 * 公知类型
 * @returns
 */
export function apiGetGovjobList() {
	return http.get('admin/v1/erp/gov_job')
}

/**
 * 获取行政区域
 * @param {*} code
 * @returns
 */
export function apiGetRegionList(code) {
	return http.get('admin/v1/public/region_list?code=' + code)
}

/**
 * 获取班级列表
 * @returns
 */
export function apiGetClassList(params) {
	return http({
		url: 'admin/v1/public/class_list',
		method: 'GET',
		params
	})
}

/**
 * 获取可用的来源
 * @returns
 */
export function apiGetCrmSourceList() {
	return http.get('admin/v1/public/crm_source')
}

/**
 * 教师编学科列表
 * @returns
 */
export function apiGetSubjectList() {
	return http.get('admin/v1/public/subject_list')
}

/**
 * 获取课程优惠券
 * @returns
 */
export function apiGetCurriculumCoupon(params) {
	return http.get('admin/v1/public/curriculum_coupon_list', { params: { curriculum_id: params } })
}

/**
 * 根据校区id获取契约模板列表
 * @returns
 */
export function apiGetQiyuesuoTemplateList(id) {
	return http.get('admin/v1/public/qiyuesuo_template_list?dept_id=' + id)
}

/**
 * 分页 获取文档列表
 * @param {*} query
 */
export function apiGetDocumentList(query) {
	return http.post('admin/v1/crm/document', query)
}

/**
 * 获取固定资产分类列表
 * @param {*}
 */
export function apiGetFaTypeList() {
	return http.get('admin/v1/public/fa_type_list')
}

/**
 * 分页 获取候选人列表
 * @param {*} query
 */
export function apiGetPeopleList() {
	return http.get('admin/v1/public/rec_people_list')
}

// 根据班级获取报名人数
export function apiGetSignCount(params) {
	return http({
		method: 'GET',
		url: 'admin/v1/public/gov_class_list',
		params
	})
}

// 根据班级获取班级授课老师列表
export function apiGetClassTeacher(params) {
	return http({
		method: 'GET',
		url: 'admin/v1/public/class_teacher_list',
		params
	})
}

// 获取全部教室
export function apiGetAllClassroom() {
	return http.get('admin/v1/public/classroom_list')
}

/**
 * 分页查询酒店房间列表
 * @param {*} query
 * @returns
 */
export function apiGetHotelRoom(params) {
	return http.get('admin/v1/public/hotel_room_list', { params })
}

/**
 * 印刷厂列表
 * @param {*} params
 * @returns
 */
export function apiGetFactory(params) {
	return http.get('admin/v1/public/factory_list', { params })
}

/**
 * 根据资产分类获取标准型号列表
 * @param {*} params
 * @returns
 */
export function apiGetFaSauList(params) {
	return http.get('admin/v1/public/fa_sau_list', { params })
}

/**
 * 获取学员的缴费记录列表
 * @param {*} query
 * @returns
 */
export function apiGetReceiptList(query) {
	return http.get('admin/v1/public/receipt_list', { params: query })
}

/**
 * 获取公职类型列表
 * @param {*} query
 * @returns
 */
export function apiGetGovJobList(query) {
	return http.get('admin/v1/public/gov_job_list', { params: query })
}

/**
 * 获取通用流程列表
 * @param {*} query
 * @returns
 */
export function apiGetWKFlowList(query) {
	return http.get('admin/v1/public/wk_flow_list', { params: query })
}

/**
 * 获取活动列表
 * @param {*} query
 * @returns
 */
export function apiGetActivityList(query) {
	return http.get('admin/v1/public/activity_list', { params: query })
}

/**
 * 获取闲置资产列表
 * @param {*} query
 * @returns
 */
export function apiGetFaAssetList(query) {
	return http.get('admin/v1/public/fa_asset_list', { params: query })
}

/**
 * 获取课程费用列表
 * @param {*} query
 * @returns
 */
export function apiGetFeeList(query) {
	return http.get('admin/v1/public/fee_list', { params: query })
}

/**
 * 获取个人信息
 * @param {*} query
 * @returns
 */
export function apiGetPersonalInfo(query) {
	return http.get('admin/v1/personal_center/get_info', { params: query })
}

/**
 * 获取契约锁本地编码列表
 * @param {*} query
 * @returns
 */
export function apiGetLocalCodeList(query) {
	return http.get('admin/v1/public/local_code_list', { params: query })
}

/**
 * 获取图书类目列表
 * @param {*} query
 * @returns
 */
export function apiGetBookCateList(query) {
	return http.get('admin/v1/public/bk_category_list', { params: query })
}

/**
 * 分页查询酒店房间列表
 * @param {*} query
 * @returns
 */
export function apiGetHotelMeetingRoom(params) {
	return http.get('admin/v1/public/hotel_meeting_list', { params })
}

// 更具班级id获取全部学员
export function apiGetClassStudent(data) {
	return http({
		method: 'POST',
		url: 'admin/v1/erp/class/get_student_list',
		data
	})
}

// 获取供应商列表
export function apiGetSupplierList(params) {
	return http.get('admin/v1/public/wms_supplier_list', { params })
}

// 获取商品类型
export function apiGetGoodsTypeList(params) {
	return http.get('admin/v1/public/wms_category_list', { params })
}

// 获取全部仓库
export function apiGetWmsWarehouseList(params) {
	return http.get('admin/v1/public/wms_warehouse_list', { params })
}

// 获取全部库区
export function apiGetAllWmsWarehouseList() {
	return http.get('admin/v1/public/wms_warehouse_area_list')
}

// 获取全部库位
export function apiGetWmsWarehouseAreaList(params) {
	return http.get('admin/v1/public/wms_warehouse_location_list', { params })
}

// 获取全部货主
export function apiGetWmsOwnerList(params) {
	return http.get('admin/v1/public/wms_owner_list', { params })
}

// 获取全部规格
export function apiGetWmsSpecList(params) {
	return http.get('admin/v1/public/wms_spu_sku_list', { params })
}

// 获取已入库并且数量大于0的商品列表
export function apiGetWmsInStockList(params) {
	return http.get('admin/v1/public/wms_stock_list', { params })
}

// 上传文件
export function apiUploadFile(data) {
	return http({
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		method: 'post',
		url: 'admin/v1/public/import',
		data
	})
}

// 仓库管理
export function apiWmsWarehouseList(data) {
	return http.post('admin/v1/wms/stock', data)
}

// 下载补助扣款表模板
export function apiDownloadTemplete() {
	return http.get('admin/v1/public/excel_template?type=1', { responseType: 'arraybuffer' })
}

/**
 * 报名学员表
 * @param {*} data
 * @returns
 */
export function apiGetRegistration(data) {
	return http.post('admin/v1/erp/registration', data)
}

/**
 * 新缴费列表
 * @param {*} data
 * @returns
 */
export function apiGetNewReceipt(data) {
	return http.post('admin/v1/public/receipt_success_list', data)
}

export default {
	apiGetSchool,
	apiEmpList,
	getRoles,
	getEmps,
	getDepts,
	apiGetCrmSource,
	apiGetActivityChannel,
	apiGetPost,
	apiCurrList,
	apiGetGovjobList,
	apiGetRegionList,
	apiGetClassList,
	apiGetCrmSourceList,
	apiGetSignCount,
	apiGetClassTeacher,
	apiGetAllClassroom,
	apiGetHotelRoom,
	apiGetHotelMeetingRoom,
	apiGetSupplierList,
	apiGetRegistration,
	apiGetGoodsTypeList,
	apiGetWmsWarehouseList,
	apiGetAllWmsWarehouseList,
	apiGetWmsWarehouseAreaList,
	apiGetWmsOwnerList,
	apiGetWmsSpecList,
	apiGetWmsInStockList,
	apiUploadFile,
	apiGetFaAssetList,
	apiGetFeeList,
	apiGetPersonalInfo,
	apiGetLocalCodeList,
	apiGetBookCateList,
	apiGetClassStudent
}

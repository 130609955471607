<template>
    <el-cascader v-model="codes" :props="cascaderProps" :options="areaOptions" clearable @change="handleChange"
        style="width: 100%;" />
    <!-- <el-cascader v-model="codes" :options="dataList" :props="cascaderProps" clearable @change="handleChange"
        style="width: 100%;" /> -->
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
// import { apiGetRegionList } from '@/api/public'
import { provinceAndCityData, regionData } from 'element-china-area-data'

const model = defineModel({ type: String, default: '' })
const emit = defineEmits(['change'])

const codes = ref([])
const props = defineProps({
    /**
     * 选择框的层级 ，0省份，1城市，2区县
     */
    level: {
        type: Number,
        default: 0
    }
})
const cascaderProps = ref({
    leaf: 'is_leaf',
})

// const dataList = ref([])
const areaOptions = ref([])

if (props.level === 0) {
    regionData.forEach(item => {
        item.is_leaf = true
    })
    areaOptions.value = regionData
} else if (props.level === 1) {
    regionData.forEach(item => {
        item.children.forEach(city => {
            city.is_leaf = true
        })
    })
    areaOptions.value = regionData
} else {
    areaOptions.value = regionData
}


watch(() => model.value, (val) => {
    if (val && val.length === 6) {
        if (codes.value.length) return
        let province = val.substring(0, 2)
        let city = val.substring(0, 4)
        if (props.level === 0) {
            codes.value = [province]
            if (val != (province + '0000')) {
                model.value = province + '0000'
            }
        }
        if (props.level === 1) {
            if (val.substring(2) != '0000') {
                codes.value = [province, city]
                if (val != city + '00') {
                    model.value = city + '00'
                }
            }
        }
        if (props.level === 2) {
            if (val.substring(4, 2) != '00') {
                codes.value = [province, city, val]
            }
        }
    } else {
        codes.value = []
    }
}, {
    immediate: true,
    deep: true
})


/**
 * 处理选择
 */
function handleChange(e) {
    let code = ''
    if (e) {
        code = e[e.length - 1]
        if (props.level === 0) {
            code += '0000'
        } else if (props.level === 1) {
            code += '00'
        }
    }
    model.value = code
    emit('change', model.value)
}

</script>

<style lang="scss" scoped></style>
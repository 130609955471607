/*
 * @Author: Sky
 * @Date: 2024-09-11 10:07:32
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-24 17:21:34
 * @Description: desc
 */

export default {
  path: "/",
  icon: "",
  component: "",
  meta: {
    title: "我的工作",
  },
  children: [
    {
      path: "/mywork/report",
      icon: "ChatLineSquare",
      component: () => import("@/views/mywork/report"),
      power: "admin/v1/todo",
      meta: {
        title: "工作汇报",
      }
    },
    {
      path: "/",
      icon: "Tickets",
      component: "",
      meta: {
        title: "假勤",
      },
      children: [
        {
          path: "/mywork/leave/leaveWork",
          icon: "",
          component: () => import("@/views/mywork/leave/leaveWork"),
          power: "admin/v1/todo",
          meta: {
            title: "请假调休",
          }
        },

      ],
    },

    {
      path: "/mywork/notice",
      icon: "ChatLineSquare",
      component: () => import("@/views/mywork/notice"),
      power: "admin/v1/todo",
      meta: {
        title: "公告通知",
      }
    },

    {
      path: "/",
      icon: "Tickets",
      component: "",
      meta: {
        title: "待办事项",
      },
      children: [
        {
          path: "/mywork/todo/push",
          icon: "",
          component: () => import("@/views/mywork/task/push"),
          power: "admin/v1/todo",
          meta: {
            title: "我的发布",
          }
        },
        {
          path: "/mywork/todo/await",
          icon: "",
          component: () => import("@/views/mywork/task/await"),
          power: "admin/v1/todo",
          meta: {
            title: "我的待办",
          }
        },
      ],
    },

    {
      path: "/",
      icon: "Operation",
      component: "",
      meta: {
        title: "流程审批",
      },
      children: [
        {
          path: "/mywork/approve/initiateApproval",
          icon: "",
          component: () => import("@/views/mywork/approve/initiateApproval"),
          power: "admin/v1/todo",
          meta: {
            title: "发起审批",
          }
        },
        {
          path: "/mywork/approve/pending",
          icon: "",
          component: () => import("@/views/mywork/approve/pending"),
          power: "admin/v1/todo",
          meta: {
            title: "待处理",
          }
        },
        {
          path: "/mywork/approve/receive",
          icon: "",
          component: () => import("@/views/mywork/approve/receive"),
          power: "admin/v1/todo",
          meta: {
            title: "我的抄送",
          }
        },
        {
          path: "/mywork/approve/start",
          icon: "",
          component: () => import("@/views/mywork/approve/start"),
          power: "admin/v1/todo",
          meta: {
            title: "我的发起",
          }
        },
        {
          path: "/mywork/approve/approved",
          icon: "",
          component: () => import("@/views/mywork/approve/approved"),
          power: "admin/v1/todo",
          meta: {
            title: "我的已处理",
          }
        },
      ],
    },
  ],
}
<!--
 * @Description:选择岗位 v-model 传入的值是数组，示例：[{
						id: 46,
						name: 'wx134',
						avatar_url:
							'http://dcoaoss.yuandingcheng.com/1v85XAKvbFAEIwSzOZyAGY.jpeg'
					}]，:multiple="true" 是否多选，默认true
 * @Author: 琢磨先生
 * @Date: 2024-08-09 14:59:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-21 19:31:24
-->

<template>
	<span @click.stop="onOpen">
		<slot name="reference">
			<el-button plain type="primary" icon="plus" size="small"
				>选择岗位</el-button
			>
		</slot>
	</span>
	<slot>
		<div class="choose_items">
			<div class="item" v-for="item in model" :key="item">
				<el-avatar :size="25" :src="item.avatar_url"
					><span>{{ item.name.substring(0, 1) }}</span></el-avatar
				>
				<el-text size="small">{{ item.name }}</el-text>
				<el-icon @click.stop="onRemoveItem(item)"><Close /></el-icon>
			</div>
		</div>
	</slot>
	<el-dialog
		title="岗位选择"
		v-model="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="700px"
		:append-to-body="true"
	>
		<el-row>
			<el-col :span="13">
				<el-input v-model="q" placeholder="请输入岗位名称"></el-input>
				<div class="body" v-loading="loading">
					<el-scrollbar>
						<el-tree
							ref="treeRef"
							:data="deptList"
							node-key="uid"
							:default-expanded-keys="expandedKeys"
							:check-strictly="true"
							:expand-on-click-node="false"
							:check-on-click-node="false"
							show-checkbox
							:props="{
								label: 'name'
							}"
							@node-click="handleDeptNodeClick"
							@check-change="handleNodeCheck"
						>
							<template #default="{ node, data }">
								<div class="node" :class="{ dept: !data.is_emp }">
									<el-icon v-if="!data.is_emp" color="#79bbff">
										<dc-icon name="dept"></dc-icon>
									</el-icon>
									<el-avatar
										v-if="data.is_emp"
										:size="25"
										:src="data.avatar_url"
										style="margin: 0 5px"
										>{{ node.label.substring(0, 1) }}</el-avatar
									>
									<span>{{ node.label }}</span>
								</div>
							</template>
						</el-tree>
					</el-scrollbar>
				</div>
			</el-col>
			<el-col :span="11">
				<div class="header">
					<span>已选 {{ checkedList.length }} 项</span>
					<el-button type="danger" link size="small" @click="onClean"
						>清空</el-button
					>
				</div>
				<div class="checked body">
					<el-scrollbar v-if="checkedList.length">
						<div class="emp_item" v-for="item in checkedList" :key="item.id">
							<div class="flex-row item-center flex-1">
								<el-avatar
									:size="30"
									:src="item.avatar_url"
									style="margin-right: 5px"
									>{{ item.name.substring(0, 1) }}</el-avatar
								>
								<div class="emp_name flex-1">
									<div>{{ item.name }}</div>
									<div class="dept_name">{{ item.dept_name }}</div>
								</div>
							</div>
							<el-icon @click="onEmpRemove(item)">
								<Close />
							</el-icon>
						</div>
					</el-scrollbar>
					<el-empty
						v-if="checkedList.length === 0"
						description="暂无数据"
					></el-empty>
				</div>
			</el-col>
		</el-row>
		<template #footer>
			<el-button type="primary" @click="onConfirm">确定</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from 'vue'
import { apiGetPost, getDepts } from '@/api/public'
const model = defineModel({
	type: Array,
	default: []
})
const props = defineProps({
	multiple: {
		type: Boolean,
		default: true
	}
})
const emit = defineEmits(['change'])
const treeRef = ref()
const deptList = ref([])
const tempList = ref([]) //已经加载的岗位列表记录
const checkedList = ref([]) //已经选中的岗位
const expandedKeys = ref([])
const loading = ref(false)
const visible = ref(false)
const q = ref('')
watch(
	() => model,
	(newValue) => { 
		if (newValue.value) {
			newValue.value.forEach((item) => {
				item.uid = `emp_${item.id}`
				if (!checkedList.value.find((o) => o.uid == item.uid)) {
					checkedList.value.push(item)
				}
			})
		}
	},
	{
		immediate: true,
		deep: true
	}
)

/**
 * 加载部门
 */
getDepts().then((res) => {
	if (res.code === 200 && res.data) {
		if (res.data.length) {
			res.data.forEach((item) => {
				item.uid = 'dept_' + item.id
				recursion(item)
			})
			deptList.value = res.data
			expandedKeys.value.push(res.data[0].uid)
			loadEmps(deptList.value[0])
		}
	}
})

/**
 * 递归处理部门
 * @param dept
 */
function recursion(dept) {
	if (dept.children) {
		dept.children.forEach((item) => {
			item.uid = 'dept_' + item.id
			recursion(item)
		})
	}
}

/**
 * 加载岗位
 * @param dept
 */
function loadEmps(dept) {
	loading.value = true
	apiGetPost(dept.id)
		.then((res) => {
			if (res.code === 200 && res.data) {
				dept.is_load = true
				if (!dept.children) {
					dept.children = []
				}
				res.data.forEach((emp) => {
					var item = {
						...emp,
						uid: 'emp_' + emp.id,
						is_emp: true,
						label: emp.name,
						children: []
					}
					dept.children.push(item)
					tempList.value.push(item)
				})
				//展开
				if (treeRef.value) {
					treeRef.value.store.nodesMap[dept.uid].expanded = true
				}
				setCheck()
			}
		})
		.finally(() => {
			loading.value = false
		})
}

/**
 * 点击部门项 加载岗位
 */
function handleDeptNodeClick(item) {
	if (!item.is_emp) {
		if (!item.is_load) {
			loadEmps(item)
		}
	}
}

/**
 * 选择框更改
 * @param item
 * @param checked
 */
function handleNodeCheck(item, checked) {
	if (checked) { 
		if (!props.multiple) {
			checkedList.value = [item]
		} else if (!checkedList.value.find((o) => o.uid == item.uid)) {
			checkedList.value.push(item)
		}
	} else {
		checkedList.value = checkedList.value.filter((o) => o.uid != item.uid)
	}
	setCheck()
}

/**
 * 移除选中的岗位
 * @param item
 */
function onEmpRemove(item) {
	checkedList.value = checkedList.value.filter((o) => o.uid != item.uid)
	setCheck()
}

/**
 * 清空选中
 */
function onClean() {
	checkedList.value = []
	setCheck()
}

/**
 * 确认选择
 */
function onConfirm() {
	let list = checkedList.value.map((item) => {
		return {
			id: item.id,
			name: item.name,
			dept_name: item.dept_name,
			avatar_url: item.avatar_url
		}
	})
	model.value = list
	emit('change', list)
	visible.value = false
}

const onOpen = () => {
	visible.value = true
	// if (model.value.length) {
	// 	console.log(model.value)
	// 	checkedList.value = [...model.value]
	// }
	setCheck()
}

function setCheck() {
	nextTick(() => {
		if (treeRef.value) {
			treeRef.value.setCheckedKeys(checkedList.value.map((o) => o.uid))
		}
	})
}

/**
 * 删除已经选中的岗位
 */
function onRemoveItem(item) {
	checkedList.value = checkedList.value.filter((o) => o.id != item.id)
	onConfirm()
	setCheck()
}
</script>

<style lang="scss" scoped>
.el-col {
	height: 400px;
	border: 1px solid var(--el-border-color);
	display: flex;
	flex-direction: column;
}

.el-col:first-child {
	border-right: none;
}

.el-col .el-input {
	padding: 5px;
}

.header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 10px;
	justify-content: space-between;
	border-bottom: 1px solid var(--el-border-color);
}

:deep(.el-tree) {
	--el-tree-node-content-height: 32px;
}

.node {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

:deep(.el-tree-node__content:has(.dept) > .el-checkbox) {
	display: none;
}

.body {
	flex-grow: 1;
	height: 1px;
}

.checked.body .el-scrollbar {
	padding: 10px;
}

.emp_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 5px 10px;
	border-radius: 4px;

	&:hover {
		background-color: #f5f7fa;
	}
}

.emp_item .dept_name {
	font-size: 12px;
	color: #c0c4cc;
}

// 已选择
.choose_items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;
	width: 100%;
}

.choose_items .item {
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 3px 5px;
	border-radius: 15px;
	background-color: #f5f6f6;
	font-size: 14px;
	line-height: 14px;
}

.choose_items .el-icon {
	cursor: pointer;
	padding: 2px;
	margin-left: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.choose_items .el-icon:hover {
	background-color: #999;
}
.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}
.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}
.choose_items .item .el-avatar span {
	transform: scale(0.8);
	position: absolute;
	display: flex;
	align-items: center;
}

.choose_items .el-text {
	margin-left: 3px;
}
</style>

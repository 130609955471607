<!--
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-25 22:42:40
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-26 15:40:37
 * @FilePath: \dc.amin\src\components\dc\choose\GoodsType.vue
 * @Description: 商品分类组件
-->
<template>
	<el-cascader
		v-model="model"
		:options="options"
		v-bind="$attrs"
		:props="{
			checkStrictly: false,
			emitPath: false,
			value: 'id',
			label: 'name'
		}"
		style="width: 100%"
	/>
</template>

<script setup>
import { apiGetGoodsTypeList } from '@/api/public'
import { onMounted, ref } from 'vue'

const options = ref([])
const model = defineModel()

onMounted(() => {
	apiGetGoodsTypeList().then((res) => {
		options.value = res.data
	})
})
</script>

<style lang="scss" scoped></style>

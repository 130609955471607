<!--
 * @Author: cts 308360781@qq.com
 * @Date: 2024-10-29 09:03:19
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-31 22:14:31
 * @FilePath: \dc.amin\src\components\dc\form\WangEditor.vue
 * @Description: 
-->
<template>
	<div class="editor_wrp" v-loading="loading" element-loading-text="文件上传中...">
		<Toolbar class="bar" :editor="editorRef" :defaultConfig="toolbarConfig" mode="default" />
		<Editor
			style="height: 600px; overflow: hidden"
			v-model="model"
			:defaultConfig="editorConfig"
			mode="default"
			@onCreated="handleCreated"
			class="editor"
		/>
	</div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import setting_api from '@/api/setting'
import { uploadFile, FileTypes } from '@/utils/upload_util'

const model = defineModel()
const editorRef = shallowRef()
const loading = ref(false)
const toolbarConfig = {}
let settings = {}

setting_api.getPublicSetting().then((res) => {
	if (res.code === 200) {
		settings = res.data
	}
})

//编辑器配置
let editorConfig = {
	placeholder: '请输入内容...',
	// 所有的菜单配置，都要在 MENU_CONF 属性下
	MENU_CONF: {
		insertImage: {
			//onInsertedImage: getOnInsertedImage()
		},
		// 配置上传图片
		uploadImage: {
			async customUpload(file, insertFn) {
				// 自己实现上传，并得到图片 url alt href
				let url = '',
					alt = '',
					href = ''
				uploadFile(file, FileTypes.IMAGE, settings)
					.then((res) => {
						insertFn(res.file_url, alt, href)
					})
					.catch((err) => {
						// ctx.onError()
					})
			}
		},
		uploadVideo: {
			async customUpload(file, insertFn) {
				// JS 语法
				// file 即选中的文件
				// 自己实现上传，并得到视频 url poster
				// 最后插入视频
				insertFn(url, poster)
				uploadFile(file, FileTypes.VIDEO, settings)
					.then((res) => {
						insertFn(res.file_url, '')
					})
					.catch((err) => {
						// ctx.onError()
					})
			}
		}
	}
}

const handleCreated = (editor) => {
	editorRef.value = editor
	// saveImageList.value = editor.getElemsByType('image')
	// uploadImageList.value = copyObject(saveImageList.value)
}

onBeforeUnmount(() => {
	editorRef.value?.destroy()
})
</script>

<style lang="scss" scoped>
.editor_wrp {
	width: 100%;
	border: 1px solid var(--el-border-color);
	position: relative;
}

.editor_wrp .bar {
	border-bottom: 1px solid var(--el-border-color);
}
.editor_wrp .editor {
	height: 600px;
	width: 100%;
	line-height: normal !important;
}
:deep(.w-e-text-container p) {
	margin: 0;
}
:deep(.w-e-text-placeholder) {
	top: 0px;
}
</style>

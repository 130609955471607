<!--
 * @Author: GW 1519964028@.qq.com
 * @Date: 2024-09-27 09:24:04
 * @LastEditors: 鱿鱼须 1519964028@qq.com
 * @LastEditTime: 2024-10-19 17:39:22
 * @FilePath: \dc.amin\src\components\dc\choose\Comp.vue
 * @Description: 规格组件
-->
<template>
	<el-select
		v-model="model"
		filterable
		remote
		:remote-method="remoteMethod"
		placeholder="请选择"
		value-key="sku_id"
		remote-show-suffix
		@focus="handelrList('')"
		:loading="loading"
	>
		<el-option
			v-for="item in list"
			:key="item.sku_id"
			:label="item.name"
			:value="props.attrs.isName ? { sku_id: item.sku_id, sku_name: item.name } : item.sku_id"
		/>
	</el-select>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { apiGetWmsSpecList, apiGetWmsInStockList } from '@/api/public'
const loading = ref(false)
const list = ref([])
const model = defineModel()

const props = defineProps({
	attrs: {
		type: Object,
		default: () => ({
			isName: false,
			isStored: false // 只查找已入库并且数量大于0的商品
		})
	}
})

const handelrList = async (query = '') => {
	loading.value = true
	const { data } = props.attrs.isStored
		? await apiGetWmsInStockList({
				page: 1,
				limit: 50,
				keyword: query
		  })
		: await apiGetWmsSpecList({
				page: 1,
				limit: 50,
				keyword: query
		  })
	list.value = data.data
	loading.value = false
}
const remoteMethod = async (query) => {
	if (query) {
		handelrList(query)
	} else {
		list.value = []
	}
}

onMounted(() => {
	handelrList()
})
</script>

<style lang="scss" scoped></style>

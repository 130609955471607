<!--
 * @Author: cts 308360781@qq.com
 * @Date: 2024-10-10 16:45:01
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-15 15:06:48
 * @FilePath: \dc.amin\src\components\dc\choose\GovJob.vue
 * @Description: 公职类型选择
-->
<template>
	<el-select v-model="model" placeholder="请选择" clearable>
		<el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script setup>
import { ref } from 'vue'
import { apiGetGovjobList } from '@/api/public'
const model = defineModel({ type: Number, default: null })
const dataList = ref([])
apiGetGovjobList().then((res) => {
	if (res.code === 200 && res.data) {
		dataList.value = res.data
	}
})
</script>

<style lang="scss" scoped></style>

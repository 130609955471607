/*
 * @Description: 文件上传至阿里云工具类型
 * @Author: 琢磨先生
 * @Date: 2024-08-08 11:41:39
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-09 14:45:46
 */
import http from '@/http/axios_init'
import { fileType, fileMd5 } from './file_util'
import { nanoid } from 'nanoid'
const OSS = require('ali-oss')

export const FileTypes = {
	IMAGE: 1,
	VIDEO: 2,
	FILE: 3
}

/**
 * 上传文件
 * @param {*} file 文件
 * @param {*} fileType 1：图片，2:视频，3:文件
 * @param {*} config 阿里云存储桶配置
 * @returns
 */
export function uploadFile(file, fileType, config, options) {
	var client = new OSS({
		region: config.aliyun_oss_region,
		accessKeyId: config.aliyun_oss_access_id,
		accessKeySecret: config.aliyun_oss_access_secret,
		bucket: config.aliyun_oss_bucket
	})

	return new Promise(async (resolve, reject) => {
		var md5 = await fileMd5(file)
		var res = await http.get(`admin/v1/public/file/store/get_info?md5=${md5}`)
		if (res.code == 200 && res.data) {
			resolve({
				file_url: res.data.file_url,
				ext: res.data.ext,
				size: res.data.size,
				file_type: res.data.file_type,
				name: res.data.name
			})
			return
		}

		var model = {
			size: file.size,
			file_type: fileType,
			md5: md5,
			name: file.name,
			ext: file.name.substring(file.name.lastIndexOf('.'))
		}

		//获取创建文件名称
		var fileName = `${fileType}${nanoid()}${model.ext}`

		// model.name = `${fileName}${model.ext}`
		// var thumbUrl = `https://${client.options.bucket}.${client.options.endpoint.host}/${fileName}`
		var thumbUrl = `${config.aliyun_oss_domain.replace(/\/$/, '')}/${fileName}`
		// var res = await client.put(model.name, file, options)
		var res = await client.multipartUpload(
			fileName,
			file,
			Object.assign({}, options)
		)
		if (res.res.status == 200) {
			model.file_url = thumbUrl
			//存储记录
			http.post('admin/v1/public/file/store/add', model).then((res) => {})
			resolve({
				file_url: thumbUrl,
				ext: model.ext,
				size: model.size,
				file_type: model.file_type,
				name: model.name
			})
		}
		reject('上传OSS过程错误')
	})
}

export default {
	uploadFile
}

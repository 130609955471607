/*
 * @Description: 流程与表单配置菜单/
 * @Author: 琢磨先生
 * @Date: 2024-07-25 17:00:00
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-25 17:06:27
 */
export default {
	path: '/',
	icon: '',
	component: '',
	meta: {
		title: '流程审批'
	},
	children: [
		{
			path: '/',
			icon: 'Operation',
			meta: {
				title: '动态表单'
			},
			children: [
				{
					path: '/process/form/record',
					icon: '',
					component: () => import('@/views/process/form/record'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '数据记录'
					}
				},
				{
					path: '/process/form/dynamic',
					icon: '',
					component: () => import('@/views/process/form/dynamic'),
					power: 'admin/v1/kq/plan',
					meta: {
						title: '表单设计'
					}
				}
			]
		},
		{
			path: '/',
			icon: 'Operation',
			meta: {
				title: '流程管理'
			},
			children: [
				{
					path: '/process/workflow/general',
					icon: '',
					component: () => import('@/views/process/workflow/general'),
					power: 'admin/v1/kq/plan',
					meta: { title: '通用审批' }
				},
				{
					path: '/process/workflow/specific',
					icon: '',
					component: () => import('@/views/process/workflow/specific'),
					power: 'admin/v1/kq/plan',
					meta: { title: '业务审批' }
				}
			]
		}
	]
}

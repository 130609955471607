<!--
 * @Description: 头像上传，返回访问的url地址
 * @Author: 琢磨先生
 * @Date: 2024-07-27 11:37:44
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-20 23:41:37
-->
<template>
	<el-upload
		class="avatar-uploader"
		ref="upload"
		action=""
		:limit="1"
		accept="image/*"
		:show-file-list="false"
		:http-request="handleUpload"
		:on-success="handleAvatarSuccess"
		:before-upload="beforeAvatarUpload"
		:on-exceed="handleExceed"
	>
		<div v-if="imageUrl" class="thumbnail" v-loading="loading">
			<img v-if="imageUrl" :src="imageUrl" class="avatar" />
		</div>
		<el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
	</el-upload>
</template>

<script setup>
import { onMounted, ref, toRefs, watch } from 'vue'
import setting_api from '@/api/setting'
import { uploadFile, FileTypes } from '@/utils/upload_util'
const model = defineModel({ type: String, default: '' })
const emit = defineEmits(['change'])
const imageUrl = ref(model.value)
const upload = ref()
const loading = ref(false)

watch(()=>model.value, (newVal) => {
	imageUrl.value = newVal
})



var settings = {}
setting_api.getPublicSetting().then((res) => {
	if (res.code === 200) {
		settings = res.data
	}
})
/**
 * 执行上传
 * @param ctx
 */
function handleUpload(ctx) {
	loading.value = true
	uploadFile(ctx.file, FileTypes.IMAGE, settings)
		.then((res) => {
			ctx.onSuccess()
			model.value = res.file_url
			emit('change', res.file_url)
		})
		.catch((err) => {
			ctx.onError()
		})
}

/**
 * 上传成功
 * @param f
 */
function handleAvatarSuccess(f) {
	loading.value = false
}

/**
 * 显示缩略图
 * @param f
 */
function beforeAvatarUpload(f) {
	var fr = new FileReader()
	fr.onloadend = function (e) {
		imageUrl.value = e.target.result
	}
	// 以数据URL的形式读取文件
	fr.readAsDataURL(f)
}

/**
 * 覆盖文件
 * @param files
 */
function handleExceed(files) {
	upload.value.clearFiles()
	upload.value.handleStart(files[0])
	upload.value.submit()
}
</script>

<style scoped>
.avatar-uploader {
	display: inline-block;
	--width: 120px;
	--height: 120px;
}

.avatar-uploader .avatar {
	width: var(--width);
	height: var(--height);
	display: block;
}
.avatar-uploader :deep(.el-upload) {
	border: 1px dashed var(--el-border-color);
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	transition: var(--el-transition-duration-fast);
}

.avatar-uploader :deep(.el-upload:hover) {
	border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: var(--width);
	height: var(--height);
	text-align: center;
}

.avatar-uploader .thumbnail {
	width: var(--width);
	height: var(--height);
	display: block;
	overflow: hidden;
	position: relative;
}
</style>

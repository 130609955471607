<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-12 11:51:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-12 17:02:00
-->
<template>
  <el-scrollbar>
    <el-menu
      :router="true"
      background-color="#FAFBFC"
      text-color="#172B4D"
      :default-active="activeIndex"
    >
      <!-- <el-menu-item index="0" :route="{ path: '/dashboard' }">
        <el-icon> <house /> </el-icon>首页
      </el-menu-item> -->
      <sub-menu
        v-for="item in menus"
        :key="item"
        :father="item"
        :menus="item.children"
      ></sub-menu>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubMenu from "./submenu";

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      menus: [],
      meta: {}, 
    };
  },
  props: ["routes","activeIndex"],
  watch: {
    routes: {
      handler(arr) {
        this.menus = arr;
      },
      immediate: true,
    },
  }, 
};
</script>

<style scoped></style>

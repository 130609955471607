/*
 * @Author: Sky
 * @Date: 2024-09-23 09:19:22
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-25 11:22:49
 * @Description: desc
 */
export default {
  path: '/',
  icon: '',
  component: '',
  meta: {
    title: '固定资产'
  },
  children: [
    {
      path: "/fixedAssets/asset",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/asset"),
      power: "admin/v1/asset",
      meta: {
        title: "资产列表",
      }
    },
    {
      path: "/fixedAssets/collect",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/collect"),
      power: "admin/v1/collect",
      meta: {
        title: "领用",
      }
    },
    {
      path: "/fixedAssets/repair",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/repair"),
      power: "admin/v1/repair",
      meta: {
        title: "维修登记",
      }
    },
    {
      path: "/fixedAssets/returnInventory",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/returnInventory"),
      power: "admin/v1/returnInventory",
      meta: {
        title: "借用归还",
      }
    },
    {
      path: "/fixedAssets/cleanUp",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/cleanUp"),
      power: "admin/v1/cleanUp",
      meta: {
        title: "清理报废",
      }
    },
    {
      path: "/fixedAssets/borrowReturn",
      icon: "ChatLineSquare",
      component: () => import("@/views/fixedAssets/borrowReturn"),
      power: "admin/v1/borrowReturn",
      meta: {
        title: "退库",
      }
    },
    {
      path: '/',
      icon: 'setting',
      component: '',
      meta: {
        title: '设置'
      },
      children: [
        {
          path: '/fixedAssets/setting/source',
          icon: '',
          component: () => import('@/views/fixedAssets/setting/source'),
          power: 'admin/v1/source',
          meta: {
            title: '来源'
          }
        },
        {
          path: '/fixedAssets/setting/encoding',
          icon: '',
          component: () => import('@/views/fixedAssets/setting/encoding'),
          power: 'admin/v1/encoding',
          meta: {
            title: '资产编码规则'
          }
        },
        {
          path: '/fixedAssets/setting/classification',
          icon: '',
          component: () => import('@/views/fixedAssets/setting/classification'),
          power: 'admin/v1/classification',
          meta: {
            title: '资产分类'
          }
        },
      ]
    }
  ]
}

<!-- 获取全部库区 -->
<template>
	<el-select v-model="model" placeholder="请选择库区">
		<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id" />
	</el-select>
</template>

<script setup>
import { apiGetAllWmsWarehouseList } from '@/api/public'
import { onMounted, ref } from 'vue'
const model = defineModel()
const list = ref([])
onMounted(async () => {
	const { data } = await apiGetAllWmsWarehouseList()
	list.value = data
})
</script>

<style lang="scss" scoped></style>

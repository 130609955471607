<!--
 * @Description: 课程优惠券选择  v-model 传入的值是数组，示例：[{
						id: 2,
						name: '临沂地区',
					}]
 * @Author: 琢磨先生
 * @Date: 2024-08-23 08:52:33
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-10-15 15:27:58
-->
<template>
	<span @click.stop="onOpen">
		<slot name="reference">
			<el-button plain type="default" icon="plus" size="small">选择优惠券</el-button>
		</slot>
	</span>
	<slot>
		<div class="choose_wrp">
			<div class="item" v-for="item in model" :key="item">
				<el-avatar :size="25">
					<el-icon><Money /></el-icon>
				</el-avatar>
				<el-text size="small">{{ item.name }}({{ item.type_name }}) ￥{{ item.amount }}</el-text>
				<el-icon class="close" @click.stop="onRemoveItem(item)"><Close /></el-icon>
			</div>
		</div>
	</slot>
	<slot name="append"></slot>
	<el-dialog
		title="请选择"
		v-model="visible"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="800px"
		:append-to-body="true"
	>
		<el-row>
			<el-col :span="13">
				<el-input
					v-model="query.keywords"
					placeholder="请输入名称"
					clearable
					@input="onSearch"
				></el-input>

				<div class="body">
					<el-table
						ref="tableRef"
						v-loading="loading"
						:data="tableData"
						border
						:row-key="(row) => row.id"
						:highlight-current-row="true"
						:default-expand-all="false"
						style="height: 100%"
					>
						<el-table-column label="" fixed="left" width="50">
							<template #default="{ row }">
								<el-checkbox v-model="row.checked" @change="onCheckChange(row)"></el-checkbox>
							</template>
						</el-table-column>
						<el-table-column label="类型" prop="type_name" width="150"></el-table-column>
						<el-table-column label="名称" prop="name" min-width="240"> </el-table-column>
						<el-table-column label="金额" prop="amount" width="180"></el-table-column>
						<el-table-column label="叠加" width="80">
							<template #default="{ row }">
								{{ row.is_overlay ? '是' : '' }}
							</template>
						</el-table-column>
						<!-- <el-table-column label="省份" prop="region_code_name" width="200"></el-table-column> -->
					</el-table>
					<!-- <el-pagination
						v-if="tableData.total > 0"
						:total="tableData.total"
						:page-size="query.limit"
						:current-page="tableData.current_page"
						layout="total, prev, pager, next"
						@current-change="onPageChange"
					></el-pagination> -->
				</div>
			</el-col>
			<el-col :span="11">
				<div class="header">
					<span>已选 {{ checkList.length }} 项</span>
					<el-button type="danger" link size="small" @click="onClean">清空</el-button>
				</div>
				<div class="checked body">
					<el-scrollbar v-if="checkList.length">
						<div class="select_item" v-for="item in checkList" :key="item.id">
							<div class="flex-row item-center flex-1">
								<el-avatar :size="30" style="margin-right: 5px">
									<el-icon><Document /></el-icon
								></el-avatar>
								<div class="flex-1">
									<div>{{ item.name }} ￥{{ item.amount }}</div>
									<!-- <div class="dept_name">{{ item.dept_name }}</div> -->
								</div>
							</div>
							<el-icon @click="onRemove(item)">
								<Close />
							</el-icon>
						</div>
					</el-scrollbar>
					<el-empty v-if="checkList.length === 0" description="暂无数据"></el-empty>
				</div>
			</el-col>
		</el-row>
		<template #footer>
			<el-button type="primary" @click="onConfirm">确定</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import { apiGetCurriculumCoupon } from '@/api/public'
import { ElMessage } from 'element-plus'
const model = defineModel({ type: Array, default: [] })
const props = defineProps({
	/**
	 * 课程id
	 */
	curriculumId: {
		type: Number
		// default: true
	}
})
const emit = defineEmits(['change'])
const visible = ref(false)
const loading = ref(false)
const tableRef = ref(null)
const query = ref({
	keywords: ''
})
//1：满减优惠，2：内部优惠，3：限时优惠
const typeOptions = ref([
	{
		label: '满减优惠',
		value: 1
	},
	{
		label: '内部优惠',
		value: 2
	},
	{
		label: '限时优惠',
		value: 3
	}
])

const checkList = ref([])
const tableData = ref([])

watch(
	() => model.value,
	(val) => {
		checkList.value = []
		if (val) {
			val.forEach((item) => {
				if (!checkList.value.find((o) => o.id === item.id)) {
                    item.type_name = typeOptions.value.find((o) => o.value == item.type)?.label
					checkList.value.push(item)
				}
			})
		}
	},
	{
		deep: true,
		immediate: true
	}
)

watch(
	() => props.curriculumId,
	(val) => {
		// console.log('课程id', val)
		loadData()
	}
)

// onMounted(() => {
// 	loadData()
// })

/**
 *
 */
function onPageChange(val) {
	query.value.page = val
	loadData()
}

/**
 * 加载数据
 */
function loadData() {
	loading.value = true
	tableData.value = []
	apiGetCurriculumCoupon(props.curriculumId)
		.then((res) => {
			if (res.code === 200) {
				tableData.value = res.data
				tableData.value.forEach((item) => {
					item.type_name = typeOptions.value.find((o) => o.value == item.type)?.label
				})
				if (tableRef.value) {
					nextTick(() => {
						tableData.value.forEach((item) => {
							item.checked = checkList.value.find((o) => o.id == item.id) ? true : false
						})
					})
				}
			}
		})
		.finally(() => (loading.value = false))
}

/**
 * 打开 默认选中
 */
const onOpen = () => {
	visible.value = true
	checkList.value = [...model.value]
	if (tableData.value.length === 0) {
		loadData()
	} else {
		// console.log(tableData.value)
		nextTick(() => {
			tableData.value.forEach((m) => {
				m.checked = checkList.value.find((o) => o.id == m.id) ? true : false
			})
		})
	}
}

/**
 * 查询
 */
function onSearch() {
	// query.value.page = 1
	// loadData()
}

/**
 * 选择更改
 * @param item
 */
function onCheckChange(item) {
	if (item.checked) {
		if (checkList.value.find((o) => o.type === item.type)) {
			if (!item.is_overlay) {
				if (checkList.value.find((o) => o.type === item.type && !o.is_overlay)) {
					item.checked = false
					ElMessage.warning('不能叠加使用')
					return
				}
			}
		}
		checkList.value.push(item)
	} else {
		checkList.value = checkList.value.filter((m) => m.id !== item.id)
	}
}

/**
 * 移除已选择的
 * @param item
 */
function onRemoveItem(item) {
	checkList.value = checkList.value.filter((m) => m.id !== item.id)
	onConfirm()
	nextTick(() => {
		tableData.value.forEach((m) => {
			if (m.id !== item.id) {
				m.checked = false
			}
		})
	})
}

/**
 * 移除已选择
 */
function onClean() {
	checkList.value = []
	tableData.value.forEach((m) => {
		m.checked = false
	})
}

/**
 * 移除
 * @param item
 */
function onRemove(item) {
	checkList.value = checkList.value.filter((m) => m.id !== item.id)
	let m = tableData.value.find((o) => o.id == item.id)
	if (m) {
		m.checked = false
	}
}

/**
 * 确定选择
 */
function onConfirm() {
	let list = [...checkList.value]
	model.value = list
	emit('change', list)
	visible.value = false
}
</script>

<style lang="scss" scoped>
.el-col {
	height: 400px;
	border: 1px solid var(--el-border-color);
	display: flex;
	flex-direction: column;
}

.el-col:first-child {
	border-right: none;
}

.el-col .el-input {
	padding: 5px;
}

.header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 10px;
	justify-content: space-between;
	border-bottom: 1px solid var(--el-border-color);
}

.body {
	flex-grow: 1;
	height: 1px;
}

.checked.body .el-scrollbar {
	padding: 10px;
}

.select_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 5px 10px;
	border-radius: 4px;

	&:hover {
		background-color: #f5f7fa;
	}
}

.select_item .name {
	font-size: 12px;
	color: #c0c4cc;
}

// 已选择
.choose_wrp {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;
	width: 100%;
}

.choose_wrp .item {
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 4px 5px;
	background-color: #f5f6f6;
	height: 30px;
	border-radius: 15px;
}

.choose_wrp .close.el-icon {
	cursor: pointer;
	padding: 2px;
	margin-left: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.choose_wrp .close.el-icon:hover {
	background-color: #999;
}

.choose_wrp .item .el-avatar span {
	position: absolute;
	display: flex;
	align-items: center;
}

.choose_wrp .el-text {
	margin-left: 3px;
}
</style>

import cfg from "@/config/config.js";

const install = (app) => {
  /**
   * 通过api地址（url）判断是否拥有请求的权限
   */
  app.config.globalProperties.$power = (url) => {
    var apis = ['admin/v1/emp'];
    var json = localStorage.getItem(cfg.API_STORE_KEY);
    if (json) {
      apis = JSON.parse(json);
    }

    return apis.findIndex((o) => o == url.toLowerCase()) > -1;
  };
};

export default { install };
